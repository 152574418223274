import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Header from '../Includes/Header';
import { _SETTINGS_PATH } from "../../Config/Constants";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
const Skill = (props) => {
  const [skiField, setSkiField] = React.useState("");
  const [skiId, setSkiId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [skiList, setSkiList] = React.useState([]);

  React.useEffect(() => {
    getSkiList();
  }, []);
  const handleSkiInput = (e) => {
    setSkiField(e.target.value);
  }
  const handleSubmitForm = () => {
    const param = { skill: skiField, id: skiId };
    axios.post(_SETTINGS_PATH + "/addSkill", param)
      .then((response) => {
        getSkiList();
        handleClearForm();
      }).catch(err => {
        console.log(err);
      });
  }
  const getSkiList = () => {
    axios.get(_SETTINGS_PATH + "/getSkill")
      .then((response) => {
        console.log("responseresponse", response)
        setSkiList(response.data.skill);
      }).catch(err => {
        console.log(err);
      });
  }
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id };
      axios.post(_SETTINGS_PATH + "/deleteSkill", param)
        .then((response) => {
          getSkiList();
        }).catch(err => {
          console.log(err);
        });
    }
  }
  const handleEdit = (id) => {
    axios.get(_SETTINGS_PATH + "/getSkill/" + id)
      .then((response) => {
        console.log("responseresponse", response)
        if (response.data.skill) {
          setSkiField(response.data.skill.skill);
          setSkiId(response.data.skill.id);
          setOpenDialog(true);
        }
      }).catch(err => {
        console.log(err);
      });
  }
  const handleClearForm = () => {
    setSkiField("");
    setSkiId("");
    setOpenDialog(false);
  }

  return (
    <React.Fragment>
      <Header />
      {openDialog ?

        <Dialog open={openDialog} onClose={handleClearForm}>
          <DialogActions disableSpacing={true}>
            <IconButton className="close-pos close-span" onClick={e => { handleClearForm(false) }}><CloseIcon /></IconButton>
          </DialogActions>
          <DialogContent>
            <h4 className={"sethead"}>{skiId ? "Update" : "Create"} Skills</h4>
            <div style={{ minWidth: 275 }}>
                <p><TextField id="outlined-basic" fullWidth={true} label="Skill" variant="outlined" value={skiField} onChange={handleSkiInput} type="text" /></p>
                <Button disabled={skiField.length == 0}
                  onClick={handleSubmitForm} variant="contained">{skiId ? "Update" : "Create"}</Button>


              

            </div>
          </DialogContent>
        </Dialog>

        : null}
      <Container maxWidth="md" className="settings-container">

        <Grid container>
          <Grid item sm={8}>
            <h2 className={"filterhead"} >Skill List</h2>
          </Grid>
          <Grid item sm={4} style={{textAlign:"right"}}>
            <Button variant='outlined' className="settingsnbtn" onClick={e => setOpenDialog(true)}>Add Skill</Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className={"sett-table"}>
            <TableHead>
              <TableRow>
                <TableCell  padding={'none'}>Name</TableCell>
                <TableCell align="right"  padding={'none'}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {skiList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell padding={'none'} >
                    {row.skill}
                  </TableCell>
                  <TableCell  padding={'none'} align="right">
                    <IconButton onClick={e => handleEdit(row.id)} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={e => handleDelete(row.id)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>


  );
}

export default Skill;