import React from 'react';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CommentList from "../CommentList";
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
const Commentdialog = (props) => {
  const handleClearForm = () => {
    props.handleToggleComment(false);
  }

  const { empId } = props;
  return (
    <React.Fragment>

      {empId ?
        <Dialog open={empId} onClose={handleClearForm} fullWidth={true}
          maxWidth={'lg'}
          className={"view-res-dialog"}
          scroll={"body"}  >
          <DialogContent sx={{ minWidth: 475 }} className={"form-dialog-view"} style={{paddingTop:'40px'}}>
            <span className='close-span'>
                <IconButton clasName={"close-pos"} onClick={handleClearForm}>
                  <CloseIcon className={"blk"} />
                </IconButton>
                </span>
            <CommentList {...props} hideTiitle={true} title={"View Comments"} />
          </DialogContent>

        </Dialog>
        : null}

    </React.Fragment>);
}

export default Commentdialog;