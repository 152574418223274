import React from "react";
import { Grid, Icon } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { _TRIP_PATH } from "../../Config/Constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
const ClientMember = ({ clientMembers, clientId, handleEdit }) => {
  const [clientMember, setClientMember] = React.useState({
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    clientId: clientId,
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setClientMember({
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      clientId: 0,
    });
  };
  const saveMember = () => {
    const payload = { ...clientMember };
    axios
      .post(_TRIP_PATH + "/addClientMember", payload)
      .then(() => {
        handleEdit(clientId);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleEditClick = (row) => {
    setClientMember(row);
    setOpen(true);
  };
  const handleDelete = (row) => {

    const x=window.confirm("Do you want to delete?");
    if (x) {
      axios
        .post(_TRIP_PATH + "/deleteClientMember", row)
        .then((res) => {
          console.log(res)
          handleEdit(row.clientId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Grid container>
      {open ? (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Grid container>
              <Grid item sm={9}>
                <h4>
                  {typeof clientMember.id != "undefined" ? "Update" : " Add"}{" "}
                  Contact Person
                </h4>
              </Grid>
              <Grid item sm={3} style={{ textAlign: "right" }}>
                {" "}
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item sm={12}>
                <br />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item sm={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth={true}
                  label="Full Name"
                  variant="outlined"
                  type="text"
                  value={clientMember.contactName}
                  onChange={(e) => {
                    setClientMember({
                      ...clientMember,
                      contactName: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth={true}
                  label=" Email"
                  variant="outlined"
                  type="text"
                  value={clientMember.contactEmail}
                  onChange={(e) => {
                    setClientMember({
                      ...clientMember,
                      contactEmail: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth={true}
                  label=" Phone with Country Code"
                  variant="outlined"
                  type="text"
                  value={clientMember.contactPhone}
                  onChange={(e) => {
                    setClientMember({
                      ...clientMember,
                      contactPhone: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item sm={12} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  type={"primary"}
                  onClick={saveMember}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
      <Grid item sm="8">
        <h4>
          Contact Persons{" "}
          {clientMembers && clientMembers.length && (
            <span>({clientMembers.length})</span>
          )}{" "}
        </h4>
      </Grid>
      <Grid item sm="4" style={{ textAlign: "right" }}>
        <IconButton onClick={() => setOpen(!open)}>
          <AddOutlinedIcon />
        </IconButton>
      </Grid>

      <Grid item sm={12}>
        <table width={"100%"}>
          <tbody>
            {clientMembers &&
              clientMembers.map((item) => (
                <React.Fragment key={item.id}>
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #ccc",
                        paddingTop: "15px",
                      }}
                      colSpan={3}
                    >
                      <strong>{item.contactName}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{item.contactEmail}</td>
                    <td>{item.contactPhone}</td>
                    <td width={"78px"}>
                      <IconButton onClick={() => handleEditClick(item)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        fontSize="small"
                        onClick={() => handleDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
          <tr>
            <th></th>
          </tr>
        </table>
      </Grid>
    </Grid>
  );
};
export default ClientMember;
