
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Header from '../Includes/Header';
import { _SETTINGS_PATH } from "../../Config/Constants";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CloseIcon from '@mui/icons-material/Close';
const Profession = (props) => {

  const [proField, setProField] = React.useState("");//usestate hook is used to handle state in functional components
  const [proId, setProId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [proList, setProList] = React.useState([]);


  React.useEffect(() => {
    getProfessionList();
  }, []);
  const handleProInput = (e) => {
    setProField(e.target.value);
  }
  const handleSubmitForm = () => {
    const param = { proffesion: proField, id: proId };
    axios.post(_SETTINGS_PATH + "/addProffesion", param)
      .then((response) => {
        getProfessionList();
        handleClearForm();
      }).catch(err => {
        console.log(err);
      });
  }
  const getProfessionList = () => {
    axios.get(_SETTINGS_PATH + "/getProfesion")
      .then((response) => {
        console.log("responseresponse", response)
        setProList(response.data.Proffesions);
      }).catch(err => {
        console.log(err);
      });
  }
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id };
      axios.post(_SETTINGS_PATH + "/deleteProfesion", param)
        .then((response) => {
          getProfessionList();
        }).catch(err => {
          console.log(err);
        });
    }
  }
  const handleEdit = (id) => {
    axios.get(_SETTINGS_PATH + "/getProfesion/" + id)
      .then((response) => {
        console.log("responseresponse", response)
        if (response.data.proffesion) {
          setProField(response.data.proffesion.proffesion);
          setProId(response.data.proffesion.id);
          setOpenDialog(true);
        }
      }).catch(err => {
        console.log(err);
      });
  }
  const handleClearForm = () => {
    setProField("");
    setProId("");
    setOpenDialog(false);
  }

  return (
    <React.Fragment>
      <Header />

      {openDialog ?

        <Dialog open={openDialog} onClose={handleClearForm}>
          <DialogActions disableSpacing={true}>
            <IconButton className="close-pos close-span" onClick={e => { handleClearForm(false) }}><CloseIcon /></IconButton>
          </DialogActions>

          <DialogContent>
            <h4 className={"sethead"} >{proId ? "Update" : "Create"} Profession</h4>
            <div style={{ minWidth: 275 }}>

              <p><TextField id="outlined-basic" fullWidth={true} label="Profession Name" variant="outlined" value={proField} onChange={handleProInput} type="text" />
              </p>
              <Button disabled={proField.length == 0} onClick={handleSubmitForm} variant="contained">{proId ? "Update" : "Create"}</Button>

            </div>
          </DialogContent>

        </Dialog>

        : null}
      <Container maxWidth="md" className="settings-container">

        <Grid container>
          <Grid item sm={8}>
            <h2  className={"filterhead"} >Profession List</h2>
          </Grid>
          <Grid item sm={4}>
            <Button  className="settingsnbtn" variant='outlined' onClick={e => setOpenDialog(true)}>Add Profession</Button>
          </Grid>
        </Grid>


        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className={"sett-table"}>
            <TableHead>
              <TableRow>
                <TableCell  padding={'none'}>Profession</TableCell>
                <TableCell align="right"  padding={'none'}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell  padding={'none'} >
                    {row.proffesion}
                  </TableCell>
                  <TableCell align="right"  padding={'none'}>
                    <IconButton onClick={e => handleEdit(row.id)} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={e => handleDelete(row.id)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>

  );
}

export default Profession;