import React, { Component, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { _BASE_URL } from "../../../Config/Constants";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const GroupEmail = (props) => {
    const [fields, setFields] = React.useState({ subject: "", message: "", fromMail: "",greetingPrefix:"fullName" });
    const [attachment, setAttachment] = React.useState("");
    const [attachmentPath, setAttachmentPath] = React.useState("");
    const [selectedMembers,setSelectedMem]=React.useState([]);
    React.useEffect(()=>{
        const members=props.resumes.filter(mem=>props.groupMailMembers.indexOf(mem.id)!=-1);
        setSelectedMem(members);
    },[props.resumes])
    const handleChange = (field, val) => {
        const data = { ...fields };
        data[field] = val;
        console.log(props.groupMailMembers)
        setFields(data);
    }
    const getUserId = () => {
        let token = localStorage.getItem('token');
        const arr = JSON.parse(token);
        return arr['emp'];
    }
    const handleSubmit = () => {
        //groupMailMembers   
        const params = {
            'mem': props.groupMailMembers,
            subject: fields.subject,
            message: fields.message,
            fromMail: fields.fromMail,
            userId: getUserId(),
            attachment:attachmentPath,
            greetingPrefix:fields.greetingPrefix
        }

        axios.post(_BASE_URL + '/send-email', params).then((res) => {
            setFields({ subject: "", message: "", fromMail: "" });
            alert("Email send successfully");
        }).catch((error) => {
            alert("Failed to send email");
        })
    }

    const handleAttachmentUpload = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('refDoc',
            file,
            file.name
        );
        formData.append('docType', 'refDoc');
        let _url = _BASE_URL + "/FrondEndController/uploadResume";
        axios.post(_url, formData)
            .then(response => {
              //  formState['resume']=response.data.uploaded_flleinfo.resume.path;
                setAttachment(response.data.uploaded_flleinfo.resume.title);
                setAttachmentPath(response.data.uploaded_flleinfo.resume.path)
            });
    }
    return (
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth={'sm'} className={"borderradiuoss"} open={props.openGroupEmail} onClose={props.closeModal}

            >
                <DialogActions>
                    <IconButton className="close-pos close-span" onClick={props.closeModal} >
                        <CloseIcon /></IconButton>


                </DialogActions>
                <DialogContent className={"form-dialog"}>
                    <h4 className={"sethead"} >Group Mail</h4>
                    <Grid container>

                        <Grid item sm={8}>
                            <InputLabel variant="standard" className="padlefth" htmlFor="uncontrolled-native">
                                From mail
                            </InputLabel>
                            <Select sx={{ m: 1, minWidth: 200 }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="frommail"
                                variant="outlined"
                                value={fields.fromMail}
                                onChange={e => { handleChange('fromMail', e.target.value) }}

                            >

                                <MenuItem value={'job@plant-supervision.com'}>job@plant-supervision.com </MenuItem>
                                {/* <MenuItem value={'job@ds-supervision.com'}>job@ds-supervision.com</MenuItem> */}

                            </Select>
                        </Grid>

                        <Grid item sm={8}>
                            <TextField
                                fullWidth
                                id="outlined-name"
                                label="Subject"
                                value={fields.subject}
                                onChange={e => handleChange('subject', e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                fullWidth
                                id="outlined-name"
                                label="Message"
                                multiline
                                minRows={6}
                                value={fields.message}
                                onChange={e => handleChange('message', e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <label className="upd-btn">SELECT FILE
                                <input className={"hide"} type={"file"} onChange={(e) => handleAttachmentUpload(e)} />
                            </label>
                            {attachment!=""?attachment:null}
                        </Grid>
                        <Grid item sm={12}>
                            <Grid container>
                            <Grid item md={4}>
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Greetings</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={fields.greetingPrefix}
                                    onChange={e => handleChange('greetingPrefix', e.target.value)}
                                >
                                    <FormControlLabel value="fullName" control={<Radio />} label=" Name" />
                                    <FormControlLabel value="firstName" control={<Radio />} label="First Name" />
                                    <FormControlLabel value="surName" control={<Radio />} label="Surname" />
                                </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item mg={8}>
                            <Stack direction="row" spacing={1}>
                                {selectedMembers.map(item=><Chip
                                 key={item.id} 
                                label={
                                    fields.greetingPrefix=='fullName'?(item.name+" "+item.surName):(
                                    fields.greetingPrefix=='firstName'?item.name:item.surName
                                )} variant="outlined" />)}
                                
                                </Stack>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <Button onClick={handleSubmit} disabled={(fields.message == "" || fields.subject == "" || fields.fromMail == "")} variant="contained">Submit</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
export default GroupEmail;