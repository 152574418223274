import React from 'react';
import Header from './Includes/Header';
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //  logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return(<>
              <Header/>
              <h1 style={{textAlign:"center",marginTop:"20%"}}>Unexpected error</h1>
          </>
        )
        
      }
  
      return this.props.children; 
    }
  }
  export default ErrorBoundary;