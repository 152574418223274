import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({ open, onAgree, onDismiss, mainText }) {
    return (
        <Dialog
            open={open!==false}
            TransitionComponent={Transition}
            keepMounted
            onClose={onDismiss}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"xs"}
        >
            <DialogTitle>{"Waring"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {mainText ? mainText : "Are you sure to delete this?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAgree} variant="contained" size={"small"} >Agree</Button>
                <Button onClick={onDismiss} variant="outlined" size={"small"} >Disagree</Button>
            </DialogActions>
        </Dialog>
    );
}