
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Header from '../Includes/Header';
import {_SETTINGS_PATH} from "../../Config/Constants";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
const Work = (props) => { // Fat Arrow functions
  
  const [wrkField, setWkField] = React.useState("");//usestate hook is used to handle state in functional components
  const [wrkId, setWkId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [wrkList, setWkList] = React.useState([]);
  // const [userForm,setFormData]=React.useState({name:"",email:""});

  React.useEffect(() => { // this hook help us to perform lifecycle methods in react
    getWkList();
  }, []);
  const handleWkInput = (e) => {
    setWkField(e.target.value);
  }
  
  const handleSubmitForm = () => {
    const param = { work: wrkField, id: wrkId};
    axios.post(_SETTINGS_PATH+"/addWork", param)
      .then((response) => {
        getWkList();
        handleClearForm();
      }).catch(err => {
        console.log(err);
      });
  }
  const getWkList = () => {
    axios.get(_SETTINGS_PATH+"/getWork")
      .then((response) => {
        console.log("responseresponse", response)
        setWkList(response.data.work);
      }).catch(err => {
        console.log(err);
      });
  }
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id };
      axios.post(_SETTINGS_PATH+"/deleteWork", param)
        .then((response) => {
          getWkList();
        }).catch(err => {
          console.log(err);
        });
    }
  }
  const handleEdit = (id) => {
    axios.get(_SETTINGS_PATH+"/getWork/" + id)
      .then((response) => {
        console.log("responseresponse", response)
        if (response.data.work) {
          setWkField(response.data.work.work);
          setWkId(response.data.work.id);
          setOpenDialog(true);
        }
      }).catch(err => {
        console.log(err);
      });
  }
  const handleClearForm = () => {
    setWkField("");
    setWkId("");
    setOpenDialog(false);
  }

  return (
    <React.Fragment>
    <Header />
              {openDialog?

<Dialog open={openDialog} onClose={handleClearForm}>
<DialogActions disableSpacing={true}>
            <IconButton className="close-pos close-span" onClick={e => { handleClearForm(false) }}><CloseIcon /></IconButton>
          </DialogActions>

<DialogContent>
            <h4 className={"sethead"}>{wrkId ? "Update" : "Create"} Works</h4>
            <div style={{ minWidth: 275 }}>
      <p><TextField id="outlined-basic" label="Field Name" variant="outlined"  value={wrkField} onChange={handleWkInput} type="text" /></p>
      <Button disabled={wrkField.length == 0} 
      onClick={handleSubmitForm} variant="contained">{wrkId ? "Update" : "Create"}</Button>
      

      </div>
          </DialogContent>
        </Dialog>



:null}
<Container maxWidth="md" className="settings-container">
        
        <Grid container>
          <Grid item sm={8}>
          <h2 className={"filterhead"}>Fields of work</h2>
          </Grid>
          <Grid item  sm={4} style={{textAlign:"right"}}>
          <Button variant='outlined' className="settingsnbtn" onClick={e => setOpenDialog(true)}>Add Field of Work</Button>
          </Grid>
        </Grid> 

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="sett-table" >
          <TableHead>
            <TableRow>
              <TableCell  padding={'none'}>Name</TableCell>
              <TableCell align="right"  padding={'none'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wrkList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell  padding={'none'}>
                  {row.work}
                </TableCell>
                <TableCell align="right"  padding={'none'}>  <IconButton onClick={e => handleEdit(row.id)} aria-label="edit">
                  <EditIcon />
                </IconButton>
                  <IconButton onClick={e => handleDelete(row.id)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
    </React.Fragment>

  );
}

export default Work;