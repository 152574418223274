
//"https://rubiksoftwares.com/hrsoft/public";
//http://localhost/hr-app-api/public/
//https://rm.plant-supervision.com

export const _BASE_URL="https://rm.plant-supervision.com";
export const _LOGIN_URL=`${_BASE_URL}/auth/login`;
export const _OTP_URL=`${_BASE_URL}/auth/validateOTP`;
export const _FORGOT_PASS_URL=`${_BASE_URL}/auth/forgot-pass`;
export const _SETTINGS_PATH=`${_BASE_URL}/SettingsController`;
export const _USER_PATH=`${_BASE_URL}/UserController`;
export const _TRIP_PATH=`${_BASE_URL}/TripController`;

export const _PERMISSIONS=[
    {
        label:"Project",
        value:"project"
    },
    {
        label:"Client",
        value:"client"
    },
    {
        label:"Trip",
        value:"trip"
    },
    {
        label:"HR",
        value:"hr"
    },
    {
        label:"Terms and Condition",
        value:"termsCondition"
    }
];
export const _APPROVERS=[
    {
        label:"Main",
        value:"1"
    },
    {
        label:"Secondary",
        value:"2"
    }
];
