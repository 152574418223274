import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
// to perform API calls post and get
import IconButton from "@mui/material/IconButton";
import Header from "../Includes/Header";
import { _APPROVERS, _PERMISSIONS, _USER_PATH } from "../../Config/Constants";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CheckIcon from "@mui/icons-material/Check";
import HideSourceIcon from "@mui/icons-material/HideSource";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { Box, Chip } from "@mui/material";

const User = (props) => {
  // Fat Arrow functions

  const [userList, setUserList] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [userForm, setFormData] = React.useState({
    firstname: "",
    lastname: "",
    userid: "",
    email: "",
    signaturename: "",
    jobposition: "",
    phonenum: "",
    email1: "",
    email2: "",
    role: "admin",
    id_user: 0,
    permission: [],
    approver: [],
  });

  React.useEffect(() => {
    // this hook help us to perform lifecycle methods in react
    getUserList();
  }, []);
  const handleUserInput = (value, field) => {
    const fields = { ...userForm };
    fields[field] = value;
    setFormData(fields);
  };

  const handleSubmitForm = () => {
    const param = { ...userForm, approver: userForm.approver.join(",") };

    axios
      .post(_USER_PATH + "/addUser", param)
      .then((response) => {
        getUserList();
        handleClearForm();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUserList = () => {
    axios
      .get(_USER_PATH + "/getUsers")
      .then((response) => {
        console.log("responseresponse", response);
        setUserList(response.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeny = (id) => {
    if (window.confirm("Are you want to Deny this user")) {
      const param = { id: id };
      axios
        .post(_USER_PATH + "/denyUser", param)
        .then((response) => {
          getUserList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you want to Delete this user")) {
      const param = { id: id };
      axios
        .post(_USER_PATH + "/deleteUser", param)
        .then((response) => {
          getUserList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleActive = (id) => {
    if (window.confirm("Are you want to Activate this user")) {
      const param = { id: id };
      axios
        .post(_USER_PATH + "/activeUser", param)
        .then((response) => {
          getUserList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEdit = (id) => {
    const param = { id: id };
    axios
      .get(_USER_PATH + "/singleUser/" + id)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          // setEmailField(response.data.email);
          // setUserId(response.data.id);
          const fields = { ...userForm };
          fields["email"] = response.data.email;
          fields["email1"] = response.data.email1;
          fields["email2"] = response.data.email2;
          fields["firstname"] = response.data.firstname;
          fields["lastname"] = response.data.lastname;
          fields["signaturename"] = response.data.signaturename;
          fields["jobposition"] = response.data.jobposition;
          fields["phonenum"] = response.data.phonenum;
          fields["userid"] = response.data.userid;
          fields["id_user"] = response.data.id;
          fields["approver"] = response.data.approver.split(",");
          fields["permission"] = response.data.permissions
            ? JSON.parse(response.data.permissions)
            : [];
          setFormData(fields);

          setOpenDialog(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event) => {
    const fields = { ...userForm };
    fields["permission"] = event.target.value;
    setFormData(fields);
    console.log(event.target.value);
  };

  const handleClearForm = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <Header />

      {openDialog ? (
        <Dialog open={openDialog} onClose={handleClearForm}>
          <DialogActions disableSpacing={true}>
            <IconButton
              className="close-pos close-span"
              onClick={(e) => {
                handleClearForm(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <h4 className={"sethead"}>
              {userForm.id_user ? "Edit" : "Create"} User
            </h4>
            <div style={{ minWidth: 275 }}>
              <Grid container>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    value={userForm.firstname}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "firstname");
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Surname"
                    variant="outlined"
                    value={userForm.lastname}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "lastname");
                    }}
                    type="text"
                  />
                </Grid>
                <br></br>
                <br></br>
                <br></br>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="signature Name"
                    variant="outlined"
                    value={userForm.signaturename}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "signaturename");
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Job Description"
                    variant="outlined"
                    value={userForm.jobposition}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "jobposition");
                    }}
                    type="text"
                  />
                </Grid>
                <br></br>
                <br></br>
                <br></br>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    value={userForm.phonenum}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "phonenum");
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Plant Supervision Email"
                    variant="outlined"
                    value={userForm.email1}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "email1");
                    }}
                    type="text"
                  />
                </Grid>
                <br></br>
                <br></br>
                <br></br>
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Ds Supervision Email"
                    variant="outlined"
                    value={userForm.email2}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "email2");
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6}>
                  <label>
                    Permissions : <br />
                  </label>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={userForm.permission}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                      const vals = _PERMISSIONS.filter((itm) =>
                        selected.includes(itm.value)
                      );
                      return (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {vals.map((value) => (
                            <Chip key={value.value} label={value.label} />
                          ))}
                        </Box>
                      );
                    }}
                    style={{ width: "100%" }}
                  >
                    {_PERMISSIONS.map((perm) => (
                      <MenuItem key={perm.value} value={perm.value}>
                        <Checkbox
                          checked={userForm.permission.indexOf(perm.value) > -1}
                        />
                        <ListItemText primary={perm.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <br></br>
                <br></br>
                <br></br>
                {/* <Grid item sm={6}>
                    <TextField id="outlined-basic" label="User ID" variant="outlined" value={userForm.userid} onChange={e => { handleUserInput(e.target.value, 'userid') }} type="text" />    
                           </Grid> */}
                <Grid item sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Login Email"
                    variant="outlined"
                    value={userForm.email}
                    onChange={(e) => {
                      handleUserInput(e.target.value, "email");
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth={true}>
                    <InputLabel id="demo-select-small-label">
                      Invoice Approver
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={userForm.approver}
                      label="Approver"
                      renderValue={(selected) => {
                        const vals = _APPROVERS .filter((itm) =>
                        selected.includes(itm.value)
                      );
                      return (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {vals.map((value) => (
                            <Chip key={value.value} label={value.label} />
                          ))}
                        </Box>
                      );
                    }}
                      onChange={(e) => {
                        handleUserInput(e.target.value, "approver");
                      }}
                      multiple
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={'1'}>
                        <Checkbox checked={userForm.approver.indexOf('1') > -1} />
                        <ListItemText primary={"Main"} />
                      </MenuItem>
                      <MenuItem value={'2'}>
                        <Checkbox checked={userForm.approver.indexOf('2') > -1} />
                        <ListItemText primary={"Secondary"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <br></br>
                <br></br>
                <br></br>

                {/* <Grid item sm={8}>
                    <InputLabel variant="standard" className="padlefth"  htmlFor="uncontrolled-native">
                      User Role
                    </InputLabel>
                    <Select sx={{ m: 1, minWidth: 200 }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Role"
                      variant="outlined"
                      value={userForm.role} onChange={e => { handleUserInput(e.target.value, 'role') }}

                    >

                      <MenuItem value={'Dataentry Operator'}>Dataentry Operator</MenuItem>
                      <MenuItem value={'HR'}>HR</MenuItem>
                      <MenuItem value={'Admin'}>Admin</MenuItem>
                    </Select>
                  </Grid> */}
                <br></br>
                <br></br>
                <br></br>

                <Grid item sm={12} align="center">
                  <Button onClick={handleSubmitForm} variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}

      <Container maxWidth="lg" className="settings-container">
        <Grid container>
          <Grid item sm={8}>
            <h2 className={"filterhead"}>Users</h2>
          </Grid>
          <Grid item sm={4}>
            <Button
              variant="outlined"
              className="settingsnbtn"
              onClick={(e) => {
                setOpenDialog(true);
                setFormData({
                  approver: [],
                  id_user: "",
                  firstname: "",
                  lastname: "",
                  userid: "",
                  email: "",
                  signaturename: "",
                  jobposition: "",
                  phonenum: "",
                  email1: "",
                  email2: "",
                  role: "admin",
                  permission: [],
                });
              }}
            >
              Add Users
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 750 }}
            aria-label="simple table"
            className={"sett-table"}
          >
            <TableHead>
              <TableRow>
                <TableCell padding={"none"}>Name</TableCell>

                <TableCell padding={"none"}>Email</TableCell>

                <TableCell padding={"none"}>Signature Name</TableCell>
                <TableCell padding={"none"}>Job Description</TableCell>
                <TableCell padding={"none"}>Phone Number</TableCell>
                <TableCell padding={"none"}>Plant Supervision Email</TableCell>
                <TableCell padding={"none"}>Ds Supervision Email</TableCell>
                <TableCell padding={"none"}>Status</TableCell>

                <TableCell align="right" padding={"none"}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding={"none"}>
                    {row.firstname} {row.lastname}
                  </TableCell>

                  <TableCell padding={"none"}>{row.email}</TableCell>
                  <TableCell padding={"none"}>{row.signaturename}</TableCell>
                  <TableCell padding={"none"}>{row.jobposition}</TableCell>
                  <TableCell padding={"none"}>{row.phonenum}</TableCell>
                  <TableCell padding={"none"}>{row.email1}</TableCell>
                  <TableCell padding={"none"}>{row.email2}</TableCell>

                  <TableCell padding={"none"}>{row.status}</TableCell>
                  <TableCell align="right" padding={"none"}>
                    {row.status == "Deny" ? (
                      <IconButton
                        onClick={(e) => handleActive(row.id)}
                        aria-label="Active"
                      >
                        <CheckIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => handleDeny(row.id)}
                        aria-label="delete"
                      >
                        <HideSourceIcon />
                      </IconButton>
                    )}

                    <IconButton
                      onClick={(e) => handleEdit(row.id)}
                      aria-label="Edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleDelete(row.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>
  );
};

export default User;
