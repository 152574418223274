import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import axios from 'axios'; // to perform API calls post and get 
import { _BASE_URL } from "../../Config/Constants";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
const CommentList = (props) => {
    const [CommentField, setCommentField] = React.useState("");
    const [CommentId, setCommentId] = React.useState("");
    const [openComment, setCancelComment] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [editCmt, setEditComment] = React.useState({});
    const { empId, resumeData } = props;
    const [commentList, setCommentList] = React.useState(false);

    React.useEffect(() => {
        setCommentList(resumeData.comments)
    }, [resumeData]);

    const handleCommentInput = (e) => {
        setCommentField(e.target.value);
    }
    const getUserId = () => {
        let token = localStorage.getItem('token');
        const arr = JSON.parse(token);
        return arr['emp'];
    }


    const handleSubmitForm = () => {
        setLoading(true);

        const param = { comment: CommentField, emp_id: empId, user_id: getUserId(),id_comment:(typeof editCmt.id=='undefined'?0:editCmt.id) };
        axios.post(_BASE_URL + "/add-comment", param)
            .then((response) => {
                setCommentField("");
                setCommentId("");
                setLoading(false);
                setCommentList(response.data.commentList);
                setCancelComment(false);
                setEditComment({});
            }).catch(err => {
                alert("Error ! Failed to add comment")
                console.log(err);
                setLoading(false);
            });
    }


    const handleClearForm = () => {

        props.handleToggleComment(false);
    }
    const cancelComment = () => {
        setCancelComment(false);
        setCommentField("");
        setEditComment({});
    }
const editComment = (comment)=>{
    setCancelComment(true);
    setCommentField(comment.comment);
    setEditComment(comment);
}
    return (
        <React.Fragment>
            <Grid container >
                <Grid item sm={10} >
                    <h4 style={{ marginBottom: "0px",marginTop: "0px",borderBottom:"none" }} className={props.title?"filterhead dialog-title-elm":"dialog-title-elm title-padded-cmt"}> {props.title ? props.title : "Other information"}</h4>
                </Grid><Grid item sm={2} style={{ textAlign: "right", 
    paddingTop: "0px",paddingRight:"20px" }}>
                    <Button className={"viewresbtn"} onClick={e => { setCancelComment(true);setEditComment({});setCommentField(""); }}>Add comment</Button>
                </Grid>
            </Grid>

            {openComment ?
                <div>
                    <TextField id="outlined-basic" label="Comment " style={{ width: "100%" }} variant="outlined" value={CommentField} onChange={handleCommentInput} type="text" />
                   
                   
                    <div style={{ textAlign: "right", margin: "10px" }}>


                        <LoadingButton disabled={CommentField.length == 0} size="small" loading={loading}
                            loadingPosition="start" onClick={handleSubmitForm} variant="contained">{typeof editCmt.id=="undefined"?"Add":"Update"}</LoadingButton>
                        &nbsp;&nbsp;<Button size="small" onClick={cancelComment} variant="text">Cancel</Button>
                    </div>
                </div> : null}
            <div className={props.hideTiitle ? 'list-sub-container' : ''}>
                <List sx={{ width: '100%', maxWidth: 360 }} className="commentUL">

                    {commentList ? commentList.map((item, index) =>
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start"  style={{padding:"0px 0px"}}>
                                <ListItemText
                                    primary={item.comment}
                                    secondary={` - ${item.username} on ${new Date(item.created_at).toLocaleDateString()}`} 
                                  
                                /> {item.id_user ==getUserId()?<IconButton onClick={e=>editComment(item)} edge="end" aria-label="delete">
                                <EditIcon />
                              </IconButton>:null}
                            </ListItem>
                        </React.Fragment>
                    ) : <p>No comment found</p>}
                </List>
            </div>
        </React.Fragment>);
}

export default CommentList;