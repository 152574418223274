import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import * as isLeapYear from "dayjs/plugin/isLeapYear";

const UserTripList = ({
  userTrips,
  closeDialog,
  singleTrip,
  handleEdit,
  handleDelete,
  getTripList,
  triptypeList,
  addTrip,
  currentYear,
}) => {
    const [year, setYear] = useState(currentYear);
    dayjs.extend(isLeapYear);
    const dateHead=[
        {
            title:"JAN ",
            weekCount:5,
            clStart:currentYear+"-01-",
            noDays:31
        },
        {
            title:"FEB ",
            weekCount:dayjs(currentYear+'-01-01').isLeapYear()?5:4,
            clStart:currentYear+"-02-",
            noDays:dayjs(currentYear+'-01-01').isLeapYear()?29:28,

        },
        {
            title:"MAR ",
            weekCount:5,
            clStart:currentYear+"-03-",
            noDays:31   
        },
        {
            title:"APR ",
            weekCount:5,
            clStart:currentYear+"-04-",
            noDays:30   
        },
        {
            title:"MAY ",
            weekCount:5,
            clStart:currentYear+"-05-",
            noDays:31   
        },
        {
            title:"JUN ",
            weekCount:5,
            clStart:currentYear+"-06-",
            noDays:30   
        },
        {
            title:"JUL ",
            weekCount:5,
            clStart:currentYear+"-07-" ,
            noDays:31
        },        
        {
            title:"AUG ",
            weekCount:5,
            clStart:currentYear+"-08-",
            noDays:31 
        },        
        {
            title:"SEP ",
            weekCount:5,
            clStart:currentYear+"-09-",
            noDays:30  
        },
        {
            title:"OCT ",
            weekCount:5,
            clStart:currentYear+"-10-",
            noDays:31 
        },{
            title:"NOV ",
            weekCount:5,
            clStart:currentYear+"-11-",
            noDays:30 
        },
        {
            title:"DEC ",
            weekCount:5,
            clStart:currentYear+"-12-",
            noDays:31 
        },
    ];
    const getTripColInfo = (cellDate, d) => {
        console.log("cellDatecellDateXX", cellDate);
        let cls = "";
        const member = userTrips;
        let flgHastrip=false;
        let trips = member.map((mem) => {
          if (dayjs(cellDate).isBetween(mem.fromDate, mem.toDate, "day", "[]")) {
            cls = "";
            if (
              dayjs(cellDate).isBetween(
                mem.fromDate,
                dayjs(mem.fromDate).add(mem.onwardJourney-1, "day"),
                "day",
                "[]"
              ) ||
              dayjs(cellDate).isBetween(
                mem.toDate,
                dayjs(mem.toDate).subtract(mem.returnJourney-1, "day"),
                "day",
                "[]"
              )
            ) {
              cls = "_opec_cell ";
            }
            return (
              <div
                className={"_trip-days _cl_dt_cell " + cls}
                style={{ backgroundColor: mem.color }}
              >
                {d}
              </div>
            );
          }
          return null; //<div className="_no-trip"></div>;
        });
        trips = trips.filter((tr) => tr != null);
        if(trips.length==0)
      trips.push(<div className="_cl_dt_cell">{d}</div>);
        return trips;
      };
  return (
    <div>
      <table>
        <tbody>
            {dateHead.map((mon,monIdex)=><tr key={"mon-"+monIdex}>
                <td>{mon.title}</td>
                {Array.from(Array(mon.noDays).keys()).map((days)=><td>
                    {getTripColInfo(
                            currentYear + "-" + (monIdex < 9 ? "0" + (monIdex+1) : (monIdex+1))+"-"+(days<9?"0":"")+(days+1),
                            days+1
                          )}
                    </td>)}
            </tr>)}
                
        </tbody>
      </table>
    </div>
  );
};
export default UserTripList;
