import React from "react";
import dayjs from "dayjs";
import * as weekOfYear from "dayjs/plugin/weekOfYear";
import * as weekYear from "dayjs/plugin/weekYear";
import * as isBetween from "dayjs/plugin/isBetween";
import * as isLeapYear from "dayjs/plugin/isLeapYear";
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { _BASE_URL } from "../../Config/Constants";
import { Avatar } from "@mui/material";
import Chip from '@mui/material/Chip';
const TripCalendarYear = ({ tripList,currentYear,handleSingleUser }) => {
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(isBetween);
  dayjs.extend(isLeapYear);
  const [members, setMembers] = React.useState({});
  const [yearMonths,setYearMonths]=React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [img, setImg] = React.useState('');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setImg('');

  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  React.useEffect(() => {    
    const dateHead=[
        {
            title:"JAN "+currentYear,
            weekCount:5,
            clStart:currentYear+"-01-"
        },
        {
            title:"FEB "+currentYear,
            weekCount:dayjs(currentYear+'-01-01').isLeapYear()?5:4,
            clStart:currentYear+"-02-"            
        },
        {
            title:"MAR "+currentYear,
            weekCount:5,
            clStart:currentYear+"-03-"   
        },
        {
            title:"APR "+currentYear,
            weekCount:5,
            clStart:currentYear+"-04-"   
        },
        {
            title:"MAY "+currentYear,
            weekCount:5,
            clStart:currentYear+"-05-"   
        },
        {
            title:"JUN "+currentYear,
            weekCount:5,
            clStart:currentYear+"-06-"   
        },
        {
            title:"JUL "+currentYear,
            weekCount:5,
            clStart:currentYear+"-07-" 
        },        
        {
            title:"AUG "+currentYear,
            weekCount:5,
            clStart:currentYear+"-08-" 
        },        
        {
            title:"SEP "+currentYear,
            weekCount:5,
            clStart:currentYear+"-09-" 
        },
        {
            title:"OCT "+currentYear,
            weekCount:5,
            clStart:currentYear+"-10-" 
        },{
            title:"NOV "+currentYear,
            weekCount:5,
            clStart:currentYear+"-11-" 
        },
        {
            title:"DEC "+currentYear,
            weekCount:5,
            clStart:currentYear+"-12-" 
        },
    ];
    setYearMonths(dateHead);

  }, [currentYear]);
  React.useEffect(() => {
    getTripMembers();
  }, [tripList]);
  const getTripMembers = () => {
    if (tripList && typeof tripList.length != "undefined") {
      let mem = {};
      tripList.forEach((element) => {
        console.log(element);
        if (
          !mem[element.supervisor] ||
          typeof mem[element.supervisor].length == "undefined"
        ) {
          mem[element.supervisor] = [];
        }
        mem[element.supervisor].push(element);
      });
      const allTrips = Object.values(mem).flat();
      const sortedTrips = allTrips.sort((a, b) => a.supervisorName.localeCompare(b.supervisorName));
      console.log("memmemmemmemmem",sortedTrips);
      console.log("memmemmemmemmemxxxxxx",mem);
      setMembers(mem);
    } 
  }; 
  const getTripColInfo = (sub,cell, member) => {
    let cls="";
    let dayStart=""+cell.clStart+""+(((sub+1)*7)-6);
    let dayEnd=""+cell.clStart+""+((sub+1)*7);
    console.log("dayStartdayStart",dayStart)
    console.log("dayStartdayStart",dayEnd)
    const trips = member.map((mem) => {
      if(dayjs(dayStart).isBetween(
        mem.fromDate,
        mem.toDate,
        "day",
        "[]"
      )|| 
      dayjs(dayEnd).isBetween(
        mem.fromDate,
        mem.toDate,
        "day",
        "[]"
      )) {
        // cls="";
        // if( dayjs(dayStart).isBetween(mem.fromDate,dayjs(mem.fromDate).add(mem.onwardJourney, 'day'),"day","[]") ||
        // dayjs(dayStart).isBetween(mem.toDate,dayjs(mem.toDate).subtract(mem.returnJourney, 'day'),"day","[]")||
        // dayjs(dayEnd).isBetween(mem.fromDate,dayjs(mem.fromDate).add(mem.onwardJourney, 'day'),"day","[]") ||
        // dayjs(dayEnd).isBetween(mem.toDate,dayjs(mem.toDate).subtract(mem.returnJourney, 'day'),"day","[]")
        // ){
        //     cls="_opec_cell";
        // }
       return  <Tooltip title={`${mem.projectName} | ${mem.type}`} arrow>
        <div
          className={"_trip-day "+cls}
          style={{ backgroundColor: mem.color }}
        ></div></Tooltip>
      }
      return null;//<div className="_no-trip"></div>;
    });
    return trips;
  };
  return (
    <div style={{width:"100%",overflowX:"auto"}} className={"_table_wrap"}>
      <table className="_cal_table" width={"100%"}>
        <thead>
          <tr className="tr_1">
            <th className={"brd_blk headcol"}><strong>{currentYear}</strong></th>
            {yearMonths.map((item) => {
              return (
                <th colSpan={item.weekCount} key={"weekHead"+item.weekCount+item.title} className={"brd_blk"} style={{textAlign:"center"}}>
                   {item.title} 
                </th>
              );
            })}
          </tr>
          {/* <tr>
            <th className={"brd_blk"}></th>
            {yearMonths.map((item,index) => Array.from(Array(item.weekCount).keys()).map((sub)=>
              <th key={"th_" +currentYear+item.title+index+sub} className={((item.weekCount-1)==sub?"brd_blk":"")}>{sub+1}</th>
            ))}
          </tr> */}
        </thead>
        <tbody>
          {Object.keys(members).map((mem) => (
            <tr key={"mem" + members[mem][0]["supervisor"]}>
              <td className={"brd_blk name-td headcol"} >
              {/* <div aria-describedby={id} variant="contained" onClick={handleClick}>{members[mem][0]["supervisorName"]} 
               {members[mem][0]["supervisorSurname"]}
               
               <Chip className="nobg" aria-describedby={id}onClick={(e)=>{handleClick(e);setImg(members[mem][0]["supervisorphoto"]) } }
  avatar={<Avatar alt="Natacha" src={_BASE_URL+'/'+ members[mem][0]["supervisorphoto"]} />}
  label={members[mem][0]["supervisorName"]+' '+members[mem][0]["supervisorSurname"]}
/>

</div> */}
<Chip className="nobg" aria-describedby={id}onClick={(e)=>{handleClick(e);setImg(members[mem][0]["supervisorphoto"]) } }
  avatar={<Avatar alt="Natacha" src={_BASE_URL+'/'+ members[mem][0]["supervisorphoto"]} />}
  label={members[mem][0]["supervisorName"]+' '+members[mem][0]["supervisorSurname"]}
/>


               <Popover
  id={id}
  open={open}
  anchorEl={anchorEl}
  onClose={handleClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
>
<Typography sx={{ p: 2 }}><img src={_BASE_URL+'/'+ img}  width={100} />
  </Typography>
</Popover></td>

              {yearMonths.map((item,index) => Array.from(Array(item.weekCount).keys()).map((sub) => (  
                <td key={"td_" + item.title+sub} className={((item.weekCount-1)==sub?"brd_blk":"")}  onClick={()=>handleSingleUser(members[mem])}>
                    {getTripColInfo(sub,item, members[mem])}
                </td>
              )))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default TripCalendarYear;
