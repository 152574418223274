import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from '../Includes/Header';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { _USER_PATH } from "../../Config/Constants";

const PasswordReset = (props) => {
    const [pwdField, setPwdField] = React.useState("");
    const [conPwdField, setConPwdField] = React.useState("");
    const [userId, setUserid] = React.useState("");
    const handlePwdInput = (e) => {
        setPwdField(e.target.value);
    }
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const tokenArr = JSON.parse(token);
            if (tokenArr && tokenArr.role)
                setUserid(tokenArr.userid);
        }
    }, []);
    const handleSubmitForm = () => {
        const param = { password: pwdField,userId:userId };
        axios.post(_USER_PATH + "/passwordChange", param)
            .then((response) => {
                const {data} =response;
                alert(data.msg)
                handleClearForm();
            }).catch(err => {
                console.log(err);
            });
    }
    const handleClearForm = () => {
        setPwdField("");
        setConPwdField("");
    }
    return (
        <React.Fragment>
            <Header />

            <Container className="settings-container" >

                <Grid container align="center" className="nopad">
                    <Grid item sm={5} >

                        <Card>
                            <CardContent>

                                <p><TextField id="outlined-basic" label="New Password" value={pwdField} onChange={handlePwdInput} variant="outlined" /></p>
                                <p><TextField id="outlined-basic" label="Confirm Pasword" value={conPwdField} variant="outlined" onChange={e=>setConPwdField(e.target.value)} /></p>

                                <Button onClick={handleSubmitForm} disabled={
                                    pwdField==""||pwdField!==conPwdField||
                                    pwdField.length<6
                                    } variant="contained">Change Password</Button>
                                    <p>
                                        <i>*Password should contain minimum 6 characters.</i>
                                    </p>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default PasswordReset;