import React, { useEffect } from "react";
import dayjs from "dayjs";
//var weekOfYear = require('dayjs/plugin/weekOfYear');
//var weekYear = require('dayjs/plugin/weekYear'); // dependent on weekOfYear plugin
import * as weekOfYear from "dayjs/plugin/weekOfYear";
import * as weekYear from "dayjs/plugin/weekYear";
import * as isBetween from "dayjs/plugin/isBetween";
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isoWeek from 'dayjs/plugin/isoWeek'; 
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { _BASE_URL } from "../../Config/Constants";
import { Avatar } from "@mui/material";
import Chip from "@mui/material/Chip";
import { VariableSizeList   as GridTable } from 'react-window';
const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
};

const TripCalendar = ({ tripList, currentYear, handleSingleUser,scrollToday,tripListInactive,userList }) => {
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(isBetween);
  dayjs.extend(isoWeek);
  dayjs.extend(dayOfYear);
  const today = dayjs();
  const crYear=dayjs().year();
  const dayNumber = today.dayOfYear();
  const [calander, setCalander] = React.useState([]);
  const [members, setMembers] = React.useState({});
  const [membersList, setMembersList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [img, setImg] = React.useState("");
  const [weekList, setWeekList] = React.useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setImg("");
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  React.useEffect(() => {
    setTimeout(() => {
      const flavoursContainer = window.document.getElementById("wrapperCal");
      const trip_cl_blnk = window.document.getElementById("trip_cl_blnk");
      var elm = window.document.getElementsByClassName("_cl_today");
      flavoursContainer.scrollToday(
        elm[0].offsetLeft - trip_cl_blnk.offsetWidth,
        0
        );
      }, 1000);
    }, [scrollToday]);
  React.useEffect(() => {
    let weekArr = [];    
    let hasColspan=false;
    for (let i = 1; i <= 53; i++) {      
      let obj = { week: i, colSpan: 7 };
      const startDate = dayjs(currentYear).isoWeek(obj['week']).startOf('isoWeek');
      const endDate = dayjs().isoWeek( obj['week']).endOf('isoWeek');
      
      const startMon=startDate.format('MMM');
      const endMon=endDate.format('MMM');
      obj['monthText']=`${startMon} ${currentYear}`;
      if(startMon!==endMon){
        obj['monthText']=`${startMon}/${endMon} ${currentYear}`;
      }
      if (i === 1) {
        for (let j = 1; j <= 7; j++) {
          if (dayjs(currentYear + "-01-0" + j).get("day") == 1 && j != 1) {
            obj["colSpan"] = j - 1;
          }
        }
        if(obj["colSpan"]!==7)
        hasColspan=true;
        const inputDate = dayjs(currentYear+'-01-01');
        const isoWeekNumber = inputDate.isoWeek();
         const isoWeekYearNo = inputDate.isoWeekYear();
        obj['week']=isoWeekNumber;
        obj['monthText']=`Jan ${isoWeekYearNo }`;
      }
      if(i===53){
        const inputDate = dayjs(currentYear+'-12-31');
        const isoWeekNumber = inputDate.isoWeek();
        
        const isoWeekYearNo = inputDate.isoWeekYear();
        obj['week']=isoWeekNumber;
        obj['monthText']=`Dec ${isoWeekYearNo}`;
      }
      if(i!==1 && i!==53 && hasColspan)
      obj['week']=i-1;
      
     
      weekArr.push(obj);
    }
    setWeekList(weekArr);
    getDayCal(weekArr);
  }, [currentYear]);
  React.useEffect(()=>{
    const dates = dayjs(dayjs().year()+'-01-01'); // January 1, 2023
    const isoWeekNumber = dates.isoWeek();
  },[])
  React.useEffect(() => {
    getTripMembers();
  }, [tripList]);
  const getDayCal = (weekArr) => {
    let daysInMonth = dayjs(currentYear + "-01").daysInMonth();
    let Days=365;
    if(isLeapYear(currentYear)) {
      Days=366;
    } 
    let j = 1;
    let res = [];
    let mon = 1;
    let diff = weekArr.length > 0 ? 7 - weekArr[0].colSpan : 0;
    let i = 0;
    for (i = 0; i < Days; i++) {
      let dt = dayjs(
        currentYear +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (j < 10 ? "0" + j : j)
      ).format("YYYY-MM-DD");
      let _date = {
        weekName: "Wk" + i,
        date: dt,
        classVal: j % 7 == 0 ? "_weekEnd" : "_week",
        classNs: dayjs(dt).format("ddd") == "Mon" ? "mondayLink" : "",
        dayNo: j,
      };
      if (j >= daysInMonth) {
        j = 0;
        mon++;
        daysInMonth = dayjs(currentYear + "-" + mon).daysInMonth();
      }
      j++;
      res.push(_date);
    }
    setCalander(res);
  };
  const getTripMembers = () => {
    if (tripList && typeof tripList.length != "undefined") {
      let mem = {};
      tripList.forEach((element) => {
        let itKey = "_" + element.supervisor;
        if (!mem[itKey] || typeof mem[itKey].length == "undefined") {
          mem[itKey] = [];
        }
        mem[itKey].push(element);
      });

      setMembers(mem);
    }
  }
  const getUserRow=(trips,item,tripArrType)=>{
    let Days=365;
    if(isLeapYear(currentYear)) {
      Days=366;
    } 
    let hFlag=false;
  const row=Array.from(Array(Days).keys()).map((day, index) =>{    
      
    let tripData=[];
    let innerWidth=0;
    const heightArr=[0,18];
    
    if(trips["day-" + day])
     tripData= trips["day-" + day].map((trRow) => {
      hFlag = !hFlag;
      const tr={...trRow,interval:(trRow.interval===0?1:trRow.interval)};
      innerWidth=40*(Number(tr.interval)-(Number(tr.onwardJourney)+Number(tr.returnJourney)));
      innerWidth=innerWidth<0?0:innerWidth;
      return (
        <Tooltip title={`${tr.projectName} | ${tr.type}`} arrow>
          <div
            className={"_trip-dayx "}
            style={{ zIndex:700, backgroundColor: tr.color+"80",width:(40*tr.interval)+'px',height:"18px",top:(hFlag?heightArr[0]:heightArr[1])+"px"}}
            onClick={e=>{
              tripArrType==='active'? singleTripOpen(e,tripList[item],tr): singleTripOpen(e,tripListInactive[item],tr)
            }

             
            }
          >
            <div  style={{ 
              width:innerWidth+'px',
              background:tr.color,
              position:"absolute",
              left:(40*tr.onwardJourney)+"px",
              height:"17px",
              content:" "

          }}></div>
          <span style={{position:'absolute',left:"2px",  top: "-3px",zIndex:"1000",background:"transparent"}}>
          {`${tr.projectName}`}
          </span>
            
          </div>
        </Tooltip>
      );
    });
       return <td key={"th_" + day + index} className={(dayNumber === (day+1) && crYear===currentYear)?"   _cl_today":""}>
        {tripData} 
      </td>
  })
    return row;
  }
  const singleTripOpen=(e,mem,trip)=>{
    e.preventDefault();
    e.stopPropagation();
    handleSingleUser(mem,trip)
  }
  return (
    <div
      style={{ width: "100%", overflowX: "auto", maxHeight: "80vh" }}
      className={"_table_wrap"}
      id="wrapperCal"
    >
      <table className="_cal_table table-cl" width={"100%"}>
        <thead>
          <tr
            style={{
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 1200,
            }}
            className="tr_1"
          >
            <th
              align="left"
              style={{
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 1200,
              }}
              className={"brd_blk headcol"}
            >
              <strong>{currentYear}</strong>
            </th>
            {weekList.map((item, index) => {
              return (
                <th colSpan={item.colSpan} className={"brd_blk"}>
                  {calander.length > 0 ? (
                    <span className="fl-span">{item.monthText} </span>
                  ) : null}
                  <span className="fr-span">Wk{item.week}</span>
                </th>
              );
            })}
          </tr>
          <tr>
            <th
              align="left"
              style={{
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 800,
              }}
              className={"brd_blk"}
              id="trip_cl_blnk"
            ></th>
            {calander.map((item, index) => (
              <th
                key={"th_" + item.dayNo + index}
                className={
                  ((index + 1) % 7 == 0 ? "brd_blk" : "") +
                  " " +
                  item.classNs +
                  "  " +
                  (item.date == dayjs().format("YYYY-MM-DD") ? "_cl_today" : "")
                }
                style={{padding:"0px"}}
              >
               <div style={{width:"40px",textAlign:"center"}}>{item.dayNo}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        <tr>
              <td style="position: sticky; left: 0px; z-index: 800;border-color:red!important" colSpan={isLeapYear(currentYear)?367:366} className="inactive-trips">
                Project Coordinatorips
              </td>
              <td colSpan="366" class="inactive-trips"></td>
            </tr>
          {Object.keys(tripList).map((item) => (
            <tr key={item}>
              <td
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 800,
                }}
                className={"brd_blk name-td headcol"}
              >
                <Chip className="nobg" 
                label={tripList[item]["name"]+" "+tripList[item]["surName"]} 
                onClick={(e)=>{handleClick(e);setImg(tripList[item]["photo"]) } }
  avatar={<Avatar alt="Natacha" src={_BASE_URL+'/'+ tripList[item]["photo"]} />}
                />
              </td>
              {
                getUserRow(tripList[item]["trips"],item,'active')
              }
            </tr>
          ))}
          <tr>
              <td style="position: sticky; left: 0px; z-index: 800;border-color:red!important"  colSpan={isLeapYear(currentYear)?367:366} className="inactive-trips">
                InProject Coordinatorips
              </td>
              <td colspan="366" class="inactive-trips"></td>
            </tr>
            {Object.keys(tripListInactive).map((item) => (
            <tr key={item}>
              <td
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 800,
                }}
                className={"brd_blk name-td headcol"}
              >
                <Chip className="nobg" 
                label={tripListInactive[item]["name"]+" "+tripListInactive[item]["surName"]} 
                onClick={(e)=>{handleClick(e);setImg(tripListInactive[item]["photo"]) } }
  avatar={<Avatar alt="Natacha" src={_BASE_URL+'/'+ tripListInactive[item]["photo"]} />}
                />
              </td>
              {
                getUserRow(tripListInactive[item]["trips"],item,'inactive')
              }
            </tr>
          ))}
        </tbody>
      </table>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          <img src={_BASE_URL + "/" + img} width={100} />
        </Typography>
      </Popover>
    </div>
  );
};

export default TripCalendar;
