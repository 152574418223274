import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import axios from 'axios'; // to perform API calls post and get
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { _BASE_URL } from "../../../Config/Constants";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import DeleteDialog from './DeleteDialog';
const SaveSearch = (props) => {
    const [SearchField, setSearchField] = React.useState("");
    const [SearchId, setSearchId] = React.useState("");
    const [openSearch, setCancelSearch] = React.useState(false);
    const [openDelete,setOpenDelete] =  React.useState(false);
    const [loadingSearch, searchLoading] = React.useState(true);
    const [addSearchLoading, setAddSearchLoading] = React.useState(false);
    const [appliedSearch, setAppliedSearch] = React.useState(props.appliedSearch);
    const { empId, resumeData } = props;
    const [searchList, setSearchList] = React.useState(false);

    React.useEffect(() => {
        getSearch();
    }, []);
   
    const handleSearchInput = (e) => {
        setSearchField(e.target.value);
    }
    const getUserId = () => {
        let token = localStorage.getItem('token');
        const arr = JSON.parse(token);
        return arr['emp'];
    }

    const getSearch = () => {
        searchLoading(true);
        axios.get(_BASE_URL + "/get-searchList/" + getUserId())
            .then((response) => {
                setSearchList(response.data);
                searchLoading(false);
            }).catch(err => {
                alert("Error ! Failed to add search");
            });
    }
    const handleSubmitForm = () => {
        setAddSearchLoading(true);
        const param = { search: SearchField, emp_id: empId, user_id: getUserId(), searchTerm: props.searchTerm, filterOptions: props.filterOptions };
        axios.post(_BASE_URL + "/add-search", param)
            .then((response) => {
                setSearchField("");
                setSearchId("");
                setAddSearchLoading(false);
                setSearchList(response.data.searchList);
                setCancelSearch(false);
            }).catch(err => {
                alert("Error ! Failed to add search")
                console.log(err);
                setAddSearchLoading(false);
            });
    }


    const handleClearForm = () => {
        props.closeSearch(false);
    }
    const applyFilter = (index) => {
        const item = searchList[index];
        console.log(item);
        props.applyFilter(item);
    }
    const deleteFilter=(index)=>{
        setOpenDelete(index);
    }
    const confirmDelete=()=>{
        setOpenDelete(false);
        const index=openDelete;
        const item = searchList[index];
        const param={id:item.id,user_id:getUserId()};
        axios.post(_BASE_URL + "/delete-search", param)
            .then((response) => {
                setSearchField("");
                setSearchId("");
                setAddSearchLoading(false);
                setSearchList(response.data.searchList);
                setCancelSearch(false);
            }).catch(err => {
                alert("Error ! Failed to add search")
                console.log(err);
                setAddSearchLoading(false);
            });
    }
    return (
        <React.Fragment>
            {openDelete!==false?
<DeleteDialog open={openDelete} onAgree={confirmDelete} onDismiss={(e)=>setOpenDelete(false) } mainText={"Do you want to delete this?"} />
:null}
            {props.openSaveSearch ?
                <Dialog open={props.openSaveSearch} onClose={handleClearForm} className={"borderradiuoss"}>
                 
                    <DialogActions>
                    <IconButton className="close-pos close-span" onClick={e => { handleClearForm(false) }}><CloseIcon /></IconButton>

                        
                    </DialogActions>
                    <DialogContent sx={{ minWidth: 475 }}>
                    <h4 className={"sethead"} >Search list</h4>
                        <div >
                          
                                <TextField id="outlined-basic" label="Search " style={{ width: "100%" }} variant="outlined" value={SearchField} onChange={handleSearchInput} type="text" />
                                <div style={{ textAlign: "right", margin: "10px" }}>


                                    <LoadingButton disabled={SearchField.length == 0} size="small" addSearchLoading={addSearchLoading}
                                        addSearchLoadingPosition="start" onClick={handleSubmitForm} variant="contained">Create</LoadingButton>
                                </div>
                          
                        </div>
                    
                    
                        <Grid item md={12} className={"list-sub-container"}>

                                <h4 className="sub-h4">Saved searches</h4>
                              

                            </Grid>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {loadingSearch ? <CircularProgress style={{ margin: "auto", display: 'block' }} /> : null}

                            {searchList ? searchList.map((item, index) =>
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start" secondaryAction={
                                        <React.Fragment>
                                        <Button edge="end" size={"small"} disabled={(appliedSearch&&typeof appliedSearch.id!="undefined" && appliedSearch.id==item.id)} onClick={e => applyFilter(index)} varient={"contained"} aria-label="delete">
                                            Apply
                                        </Button>                                      
                                        <Button color="secondary" edge="end" size={"small"} onClick={e => deleteFilter(index)} varient={"outlined"} aria-label="delete">
                                        Delete
                                        </Button>
                                        </React.Fragment>
                                    }
                                    >
                                        <ListItemText
                                            secondary={item.title}

                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            ) : null}
                        </List>
                    </DialogContent>
                  
                </Dialog>
                : null}
        </React.Fragment>);
}

export default SaveSearch;