import React from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Header from "../Includes/Header";
import { _BASE_URL, _TRIP_PATH } from "../../Config/Constants";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import SettingsIcon from '@mui/icons-material/Settings';
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import "./trip.css";
import AddTrip from "./addTrip";
import TripCalendar from "./TripCalendar";
import TripCalendarYear from "./TripCalendarYear";
import UserTrip from "./UserTrip";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import TripFilter from "./TripFilter";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import dayjs from "dayjs";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TripYearCal from "./TripYearCal";
const TripInactive = (props) => {
  const [singleTrip, setSingleTrip] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tripList, setTripList] = React.useState([]);
  const [tripListRemote, setTripListRemote] = React.useState([]);

  
  const [tripListInactive, setTripListInactive] = React.useState([]);
  const [tripListRemoteInactive, setTripListRemoteInactive] = React.useState([]);


  const [triptypeList, setTriptypeList] = React.useState([]);
  const [calYear, setCalYear] = React.useState(dayjs().year());
  const [calType, setCalType] = React.useState(1);
  const [singleUser, setSingleUser] = React.useState([]);
  const [addTrip, setAddTrip] = React.useState(false);
  const [userFilter, setUserFilter] = React.useState({});
  const [userList, setUserList] = React.useState([]);
  const [scrollToday,setscrollToday]=React.useState(false);
  const [openLoader,setOpenLoader]=React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false)

  React.useEffect(() => {
    getTripList();
    getUserList();
    getTriptypeList();
  }, []);
  React.useEffect(()=>{
    if(calYear)
      getTripList({});
  },[calYear])
  const getTripList = (filter={}) => {
    setSingleUser([]);
    setOpenDialog(false);
    setOpenLoader(true);
    axios
      .post(`${_TRIP_PATH}/getTripInactive?year=${calYear}`,filter)
      .then((response) => {
        setTripListRemote(response.data.tripList); 
        setTripListRemoteInactive(response.data.inactiveList)      
        setAddTrip(false);
        setTripList(response.data.tripList);
        setTripListInactive(response.data.inactiveList)
        const ulist = response.data.tripList.map((item) => ({
          id: item.supervisor,
          name: item.supervisorName,
        }));
      
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{setOpenLoader(false)});
  };
  const getTriptypeList = () => {
    setOpenLoader(true);
    axios
      .get(_TRIP_PATH + "/getTriptype")
      .then((response) => {
        setTriptypeList(response.data.typeList);
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{setOpenLoader(false)});
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id.id };
      axios
        .post(_TRIP_PATH + "/deleteTrip", param)
        .then((response) => {
          getTripList({clearCache:true});
          setOpenDialog(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleEdit = (id) => {
    axios
      .get(_TRIP_PATH + "/getTrip/" + id)
      .then((response) => {
        // console.log("responseresponse", response);
        if (response.data.trip) {
          setOpenDialog(true);
          setSingleTrip(response.data.trip);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSingleUser = (user,trip=undefined) => {    
    setIsLoading(true);

    axios
      .get(_TRIP_PATH + "/getUserTrips/" + user.id)
      .then((response) => {       
         setSingleUser(response.data.tripList);
         if(trip){
          let t=response.data.tripList.find(item=>item.id===trip.trip_id);
          setSingleTrip(t);
         }else{
          setSingleTrip(response.data.tripList[0]);
         }     
        setIsLoading(false);
    
      })
      .catch((err) => {
        console.log(err);
       setIsLoading(false);

      });
  };
  const applyFilter = (filter) => {
    getTripList(filter);
  };
  const JSONToCSVConvertor=() =>{
    axios
      .get(_TRIP_PATH + "/getTrip/0/"+calYear+"?page=0")
      .then((response) => {
        const trRm=[...response.data.tripList];
        const JSONData=[...trRm].map(item=>{
          return {
           // "Client":item.clientName,
            "Project":item.projectName,
            "Supervisor":item.supervisorName+" "+item.supervisorSurname,
            "Travel Type":item.travelType,
            "From date":item.fromDate,
            "To date":item.toDate,
            "Description":item.description,
            "Onward Journy":item.onwardJourney,
            "Return Journy":item.returnJourney,
            "Country":item.countryName,
          }
        })
        const ReportTitle="";
        const ShowLabel=true;
        let arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
        let CSV = '';      
        CSV += ReportTitle + '';
        if (ShowLabel) {
            let row = "";
            for (let index in arrData[0]) {
                row += index + ',';
            }
            row = row.slice(0, -1);
            CSV += row + '\r\n';
        }
        for (let i = 0; i < arrData.length; i++) {
            let row = "";
            for (let index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }
    
            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        }
        if (CSV == '') {        
            alert("Invalid data");
            return;
        }  
        let fileName = "MyReport_";
        fileName += ReportTitle.replace(/ /g,"_");   
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
        let link = document.createElement("a");    
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
       
      })
      .catch((err) => {
      });
   
} 
 const getUserList = () => {
  axios
  .get(_BASE_URL + "/UserController/getUsers")
  .then((response) => {
    let ustItems=[...response.data.users];
    ustItems= ustItems.sort((a,b)=>{
      return a.firstname.localeCompare(b.firstname);
    })
    let cl = ustItems.map((data) => ({
      id: parseInt(data.id),
      value: parseInt(data.id),
      label: data.firstname + " " + data.lastname,
      isMainApr:data.approver.includes("1"),
      isSecondaryApr:data.approver.includes("2"), 
    }));
    setUserList(cl);
  })
  .catch((err) => {
    console.log(err);
  });
};
  return (
    <React.Fragment>
      <Header />
      {openLoader?
      <Backdrop
        sx={{ color: '#fff',zIndex:10000 }}
        open={openLoader}
        className="backdropList"
      >
        <CircularProgress color="inherit" />
      </Backdrop>:""}
      <Container
        maxWidth="100%"
        className="settings-container"
        style={{ marginTop: "70px" }}
      >
        <Grid container>
          <Grid item sm={5}>
            <h3 className='pagehead'>Trip Schedules</h3>
          </Grid>
          <Grid item sm={5} className="get_single_op_token alignright">
           
            <span >
              <TripFilter userList={userList} applyFilter={applyFilter} triptypeList={triptypeList} />
            </span >
            <span className="_tr_year_block">
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setCalYear(calYear - 1)}
            >
              <KeyboardArrowLeftTwoToneIcon />
            </IconButton>
            {calYear}
            <IconButton 
              size="small"
              color="primary"
              onClick={() => setCalYear(calYear + 1)}
            >
              <ChevronRightTwoToneIcon />
            </IconButton>
            </span>
            <ButtonGroup
              aria-label="Disabled elevation buttons"
              size="small" className="cale"
            >
              <Button onClick={() => setCalType(1)}  variant={calType==1?"contained":"outlined"}>
                Month
              </Button>
              <Button onClick={() => setCalType(2)} variant={calType==2?"contained":"outlined"}>
                Year
              </Button>
              {calYear===dayjs().year()?<Button size={"small"}  variant="outlined" color={"secondary"}  onClick={e=>setscrollToday(!scrollToday)} >Today</Button>:null}
            </ButtonGroup>
            
            </Grid>
            <Grid item sm={2} className="get_single_op_token alignright">
            <IconButton  className="_tr_add_btn btnmgtop" onClick={()=>window.location.href="./triptype"} style={{color:'#1a588c'}} fontSize={"large"} >
                    <SettingsIcon />
                  </IconButton>  
            <IconButton 
              className="_tr_add_btn btnmgtop"
                  variant="outlined"
                  onClick={(e) => setAddTrip(true)} style={{color:'#1a588c'}} fontSize={"large"}
                >
                  <AddBoxRoundedIcon />
                </IconButton>
                <IconButton  className="_tr_add_btn btnmgtop"  onClick={JSONToCSVConvertor} style={{color:'#1a588c'}} fontSize={"large"}>
                  <FileDownloadIcon/>
                  </IconButton>

          </Grid>
          <Grid item sm={12} className={"cal-tripData"}>
            {calType == 1 ? (
              <TripCalendar
                handleSingleUser={handleSingleUser}
                currentYear={calYear}
                tripList={tripList}
                tripTypes={triptypeList}
                scrollToday={scrollToday}
                tripListInactive={tripListInactive}
                userList={userList}
              />
            ) : (
              <TripYearCal  
              handleSingleUser={handleSingleUser}
              currentYear={calYear}
              tripList={tripList}
              tripTypes={triptypeList}
              scrollToday={scrollToday}
              tripListInactive={tripListInactive}
              userList={userList}
              />
              // <TripCalendarYear
              // userList={userList}
              //   handleSingleUser={handleSingleUser}
              //   currentYear={calYear}
              //   tripList={tripList}
              //   tripTypes={triptypeList}                
              //   tripListInactive={tripListInactive}
              // />
            )}
           {isLoading?
      <Backdrop
        sx={{ color: '#fff' }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>:
            (singleUser && singleUser.length) || addTrip ? (
              <UserTrip
              userList={userList}
                singleTripSelected={singleTrip}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                getTripList={()=>getTripList({clearCache:true})}
                triptypeList={triptypeList}
                userTrips={singleUser}
                addTrip={addTrip}
                formActions={"add"}

                currentYear={calYear}
                closeDialog={() => {
                  setSingleUser([]);
                  setAddTrip(false);
                }}
              />
            ) : null
            }
          </Grid>
        </Grid>
  
      </Container>
    </React.Fragment>
  );
};

export default TripInactive;
