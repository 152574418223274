import React from 'react';
import Header from './Includes/Header';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
const NotFound = () => {
    
     return (
        <React.Fragment>
          <Header />
         
          <Container maxWidth="md" className="settings-container">
    
            <Grid container>
              <Grid item sm={12}>
              <h1>The page you requested is not found or access denied</h1>
              </Grid>
              
            </Grid>
    
          </Container>
        </React.Fragment>
    
    
      );
    }
    
export default NotFound;