import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import { _BASE_URL, _TRIP_PATH } from "../../Config/Constants";
import { json } from "react-router-dom";
import dayjs from "dayjs";
const AddProject = ({
  singleProject,
  handleEdit,
  handleDelete,
  getProjectList,
  isView
}) => {
  const [projectForm, setprojectForm] = useState({
    projectName: "",
    projectCategory: "",
    hourPerWeek: "",
    billTo: "",
    pm: "",
    pma: "",
    projectId: "",
    clientId: "",
    status: "",
    startDate: "",
    address: "",
    country: "",
    contactPerson: "",
    comments: "",
  });
  const [clientList, setClientList] = useState([]);
  const [pmList, setPmList] = useState([{value:-1,label:"None"}]);
  const [pmaList, setPmaList] = useState([{value:-1,label:"None"}]);
  const [isForm, setIsForm] = useState(false);
  const [contPersonList, setcontPersonList] = useState([{value:-1,label:"None"}]);
  const [countryList, setCountryList] = useState([]);

  React.useEffect(() => {
    if (typeof singleProject.id != "undefined") {
      setIsForm(false);
    } else {
      setIsForm(true);
      handleClearForm();
    }
    setProjectFields();
  }, [singleProject]);

  React.useEffect(() => {
    console.log("single", projectForm);
  }, [projectForm]);
  const setProjectFields = () => {
    if (singleProject.clientId) {
      console.log("singleProjectsingleProject",singleProject)
      let client = {
        value: parseInt(singleProject.clientId),
        label: singleProject.clientName,
      };
      let pm = {
        value: parseInt(singleProject.pm),
        label: singleProject.pm==-1?"None":singleProject.pmName,
      };
      let pma = {
        value: parseInt(singleProject.pma),
        label:singleProject.pma==-1?"None": singleProject.pmaName,
      };
      let clPerson = {
        value: parseInt(singleProject.contactPerson),
        label: singleProject.contactPerson==-1?"None":singleProject.contactPersonName,
      };
      let countryLs = {
        value: parseInt(singleProject.country),
        label: singleProject.countryName||"",
      };
      console.log("countryLscountryLscountryLs",countryLs)
      
      setClientList([{ ...client }]);
      setPmList([{value:-1,label:"None"},{ ...pm }]);
      setPmaList([{value:-1,label:"None"},{ ...pma }]);
      setcontPersonList([{value:-1,label:"None"},{ ...clPerson }]);
      setCountryList([{...countryLs}]);
      setprojectForm({
        ...singleProject,
        clientId: { ...client },
        pm: { ...pm },
        pma: { ...pma },
        country:{...countryLs}
      });
    } else {
      setprojectForm(singleProject);
    }
  };
  const fetchClientList = (e) => {
    axios
      .get(_TRIP_PATH + "/getClient?search=" + e.target.value)
      .then((response) => {
        let cl = response.data.clientList.map((data) => ({
          value: parseInt(data.id),
          label: data.client,
        }));
        setClientList(cl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCountryList = (e) => {
    axios
      .get(_TRIP_PATH + "/getCountry?search=" + e.target.value)
      .then((response) => {
        let cl = response.data.countryList.map((data) => ({
          value: parseInt(data.id),
          label: data.nicename,
        }));
        setCountryList(cl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPmList = (e, item) => {
    console.log(e.target.value);
    axios
      .get(_BASE_URL + "/UserController/getUsers?search=" + e.target.value)
      .then((response) => {
        let cl = response.data.users.map((data) => ({
          value: parseInt(data.id),
          label: data.firstname + " " + data.lastname,
        }));
        if (item == "pm") setPmList(cl);
        else setPmaList([{value:-1,label:"None"},...cl]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchClientMembers = (cl) => {
    axios
      .get(_TRIP_PATH + "/getClient/" + cl.value)
      .then((response) => {
        console.log("response response", response.data.clientMembers);
        let cp = response.data.clientMembers.map((item) => ({
          value: parseInt(item.id),
          label: item.contactName,
        }));
        setcontPersonList([{value:-1,label:"None"},...cp]);
        // let cl = response.data.clientList.map((data) => ({
        //   value: parseInt(data.id),
        //   label: data.client,
        // }));
        // setClientList(cl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitForm = () => {
    let param = { ...projectForm };
    param["clientId"] = param["clientId"].value ? param["clientId"].value : 0;
    param["pm"] = param["pm"].value ? param["pm"].value : 0;
    param["pma"] = param["pma"].value ? param["pma"].value : 0;
    param["country"] = param["country"].value ? param["country"].value : 0;
    axios
      .post(_TRIP_PATH + "/addProject", param)
      .then((response) => {
        getProjectList();
        handleClearForm();
        alert(response.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClearForm = () => {
    setprojectForm({
      projectName: "",
      projectCategory: "",
      hourPerWeek: "",
      billTo: "",
      pm: "",
      pma: "",
      projectId: "",
      clientId: "",
      status: "",
      startDate: " ",
      address: "",
      country: "",
      contactPerson: "",
      comments: "",
    });
  };
  return (
    <>
      <Grid container>
        <Grid item sm={8}>
          <h4 className={"projecthead"}>
            {(isView || !isForm )?"Project details":<>
            {singleProject && typeof singleProject.id != "undefined"
              ? "Edit"
              : "Create"}{" "}
            Project
            </>}
          </h4>
        </Grid>
        {isView?"":<>
        <Grid item sm={4}>
          {singleProject && typeof singleProject.id != "undefined" ? (
            <>
              <IconButton
                onClick={(e) => {
                  setIsForm(true);
                  setProjectFields();
                }}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
              &nbsp;&nbsp;
              <IconButton
                onClick={(e) => handleDelete(singleProject.id)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </>
          ) : null}
        </Grid> </>}
      </Grid>
      {isForm ? (
        <Grid container>
          <Grid item sm={12}>
            <p>
              <TextField
                id="outlined-basic"
                fullWidth={true}
                label="Project Name"
                variant="outlined"
                size={"small"}
                value={projectForm.projectName}
                onChange={(e) => {
                  setprojectForm({
                    ...projectForm,
                    projectName: e.target.value,
                  });
                }}
                type="text"
              />
            </p>
            <p>
              <FormControl size={"small"} sx={{ width: "100%" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={projectForm.status}
                  fullWidth={true}
                  onChange={(e) => {
                    setprojectForm({
                      ...projectForm,
                      status: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"Tentative"}>Tentative</MenuItem>
                  <MenuItem value={"Ongoing"}>Ongoing</MenuItem>
                  <MenuItem value={"Upcoming"}>Upcoming</MenuItem>
                  <MenuItem value={"Completed "}>Completed</MenuItem>
                </Select>
              </FormControl>
            </p>
            <p>
              <TextField

              size="small"
              fullWidth={true}
              label="Start Date"
              variant="outlined"
              value={projectForm.startDate ? projectForm.startDate : " "}
              InputProps={{
              startAdornment: (
              <InputAdornment position="start"></InputAdornment>
              ),
              }}
                onChange={(e) => {
                  setprojectForm({
                    ...projectForm,
                    startDate: e.target.value,
                  });
                }}
                type="date"
              />
            </p>


            <p>
              <FormControl size={"small"} sx={{ width: "100%" }}>
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  value={projectForm.projectCategory}
                  fullWidth={true}
                  onChange={(e) => {
                    setprojectForm({
                      ...projectForm,
                      projectCategory: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"Local"}>Local</MenuItem>
                  <MenuItem value={"Domestic"}>Domestic</MenuItem>
                  <MenuItem value={"International"}>International</MenuItem>
                </Select>
              </FormControl>
            </p>
            <p>
              <TextField
                id="outlined-basic"
                fullWidth={true}
                label="Address"
                variant="outlined"
                size={"small"}
                value={projectForm.address}
                onChange={(e) => {
                  setprojectForm({
                    ...projectForm,
                    address: e.target.value,
                  });
                }}
                type="text"
                multiline
                rows={2}
              />
            </p>
            <p>
              <TextField
                id="outlined-basic"
                fullWidth={true}
                label="Hours per week"
                variant="outlined"
                size={"small"}
                value={projectForm.hourPerWeek}
                onChange={(e) => {
                  setprojectForm({
                    ...projectForm,
                    hourPerWeek: e.target.value,
                  });
                }}
                type="text"
              />
            </p>

            <p>
              <TextField
                id="outlined-basic"
                fullWidth={true}
                label="Invoice email"
                variant="outlined"
                size={"small"}
                value={projectForm.billTo}
                onChange={(e) => {
                  setprojectForm({
                    ...projectForm,
                    billTo: e.target.value,
                  });
                }}
                type="text"
              />
            </p>
            <p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size={"small"}
                options={clientList}
                value={projectForm.clientId}
                getOptionLabel={(option) => {
                  return option.label || "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={fetchClientList}
                    label="Client"
                    placeholder="Search  clients"
                  />
                )}
                onChange={(e, newValue) => {
                  setprojectForm({
                    ...projectForm,
                    clientId: newValue,
                  });
                  fetchClientMembers(newValue);
                }}
              />
            </p>
            <p>
              <FormControl size={"small"} sx={{ width: "100%" }}>
                <InputLabel>Contact person</InputLabel>
                
                <Select
                  label="Contact person"
                  value={projectForm.contactPerson}
                  fullWidth={true}
                  onChange={(e) => {
                    setprojectForm({
                      ...projectForm,
                      contactPerson: e.target.value,
                    });
                  }}
                >
                  {contPersonList.map((it) => (
                    <MenuItem value={it.value}>{it.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </p>
            <p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size={"small"}
                options={countryList}
                value={projectForm.country}
                getOptionLabel={(option) => {
                  return option.label || "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={fetchCountryList}
                    label="Country"
                    placeholder="Search  Country"
                  />
                )}
                onChange={(e, newValue) => {
                  setprojectForm({
                    ...projectForm,
                    country: newValue,
                  });
                }}
              />
            </p>

            <p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size={"small"}
                options={pmList}
                value={projectForm.pm}
                getOptionLabel={(option) => {
                  return option.label || "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => fetchPmList(e, "pm")}
                    label="Project Manager"
                    placeholder="Search  Project Manager"
                  />
                )}
                onChange={(e, newValue) => {
                  setprojectForm({
                    ...projectForm,
                    pm: newValue,
                  });
                }}
              />
            </p>

            <p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size={"small"}
                options={pmaList}
                value={projectForm.pma}
                getOptionLabel={(option) => {
                  return option.label || "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => fetchPmList(e, "pma")}
                    label="Project Coordinator"
                    placeholder="Search  Project Coordinator"
                  />
                )}
                onChange={(e, newValue) => {
                  setprojectForm({
                    ...projectForm,
                    pma: newValue,
                  });
                }}
              />
            </p>
            <p>
              <TextField
                id="outlined-basic"
                fullWidth={true}
                label="Comments"
                variant="outlined"
                size={"small"}
                value={projectForm.comments}
                onChange={(e) => {
                  setprojectForm({
                    ...projectForm,
                    comments: e.target.value,
                  });
                }}
                type="text"
                multiline
                rows={2}
              />
            </p>
            {typeof singleProject.id != "undefined" ? (
              <>
                <Button onClick={handleSubmitForm}  disabled={!projectForm.projectName||!projectForm.status||!projectForm.startDate||!projectForm.projectCategory|| 
                !projectForm.clientId ||!projectForm.country}
                 variant="contained">
                  Update
                </Button>
                &nbsp;&nbsp;
                <Button onClick={() => setIsForm(false)} variant="outline">
                  Cancel
                </Button>
              </>
            ) : (
              <Button               
              disabled={!projectForm.projectName||!projectForm.status||!projectForm.startDate||!projectForm.projectCategory|| 
                !projectForm.clientId ||!projectForm.country}
              onClick={handleSubmitForm}  
               variant="contained">
                Create
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container className="inf-table">
          <Grid item sm={12}>
            {typeof singleProject.id != "undefined" && (
              <Grid>
              <p className="pheadt">Project Name : <span className="pheadtt"> {singleProject.projectName}</span></p>
              <p className="pheadt">Project Status : <span className="pheadtt"> {singleProject.status}</span></p>
              <p className="pheadt">Start Date : <span className="pheadtt"> {dayjs(singleProject.startDate).format('DD-MM-YYYY')}</span></p>
              <p className="pheadt">Project Category  : <span className="pheadtt"> {singleProject.projectCategory}</span></p>
              <p className="pheadt">Hours Per Week  : <span className="pheadtt"> {singleProject.hourPerWeek}</span></p>
              <p className="pheadt">Bill To  : <span className="pheadtt"> {singleProject.billTo}</span></p>
              <p className="pheadt">Client  : <span className="pheadtt"> {singleProject.clientName}</span></p>
              <p className="pheadt">Contact Person  : <span className="pheadtt"> {singleProject.contactPerson==-1?"None":singleProject.contactPersonName}</span></p>
              <p className="pheadt">Country  : <span className="pheadtt"> {singleProject.countryName}</span></p>
              <p className="pheadt">Project Manager : <span className="pheadtt"> {singleProject.pm==-1?"None":singleProject.pmName}</span></p>
              <p className="pheadt">Project Coordinator: <span className="pheadtt"> {singleProject.pma==-1?"None":singleProject.pmaName}</span></p>
              <p className="pheadt">Comments : <span className="pheadtt"> {singleProject.comments}</span></p>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default AddProject;
