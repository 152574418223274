import { Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { _BASE_URL } from "../../Config/Constants"
const getProfCombained = (proffesion) => {
    const arr = proffesion.map((item) => item.proffesion);
    return arr.join(',');
}
const UserCard=({userId})=>{
    const [resumeData,setResumeData] =useState({id:""})
    useEffect(()=>{
        axios.get(_BASE_URL + "/get-resume/" + userId)
        .then((result) => {
          if (result.status == 200) {
            //this.setState({ resumeData: result.data, viewResumeLoading: false });
            setResumeData(result.data);
            console.log("resumeData")
          }
        })
    },[userId])

    return <>
     <Typography sx={{ p: 2 }}>
        {resumeData.id?<>
            <img src={resumeData.img} width={100} />
            {resumeData.name ?  <p>Name: {resumeData.name}</p> :null}
            {resumeData.phone ?<p>Phone: {resumeData.phone}</p> :null}
            {resumeData.emailPrivate ? <p>Email(Private): {resumeData.emailPrivate}</p> :null}
            {resumeData.emailSupervision ?<p>Email(Supervision): {resumeData.emailSupervision}</p> :null}
            {resumeData?.nation?.nation ?<p>Nationality: {resumeData?.nation?.nation}</p> :null}
            {resumeData?.residences?.residence ?<p>Place of Residence: {resumeData?.residences?.residence}</p> :null}
            {resumeData?.proffesions ?<p>Profession: {getProfCombained(resumeData.proffesions)}</p> :null}

                    
          </>:"Loading" }
        </Typography>
    </>
}

export default UserCard