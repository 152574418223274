import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Header from '../Includes/Header';
import { _SETTINGS_PATH } from "../../Config/Constants";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
const Nationality = (props) => {
    const [braField, setBraField] = React.useState("");
    const [braId, setBraId] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [braList, setBraList] = React.useState([]);
  
    React.useEffect(() => {
      getBraList();
    }, []);
    const handleBraInput = (e) => {
        setBraField(e.target.value);
      }
      const handleSubmitForm = () => {
        const param = { nationality: braField, id: braId };
        axios.post(_SETTINGS_PATH + "/addNationality", param)
          .then((response) => {
            getBraList();
            handleClearForm();
          }).catch(err => {
            console.log(err);
          });
      }
      const getBraList = () => {
        axios.get(_SETTINGS_PATH + "/getNationality")
          .then((response) => {
            console.log("responseresponse", response)
            setBraList(response.data.nationalitys);
          }).catch(err => {
            console.log(err);
          });
      }
      const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete?")) {
          const param = { id: id };
          axios.post(_SETTINGS_PATH + "/deleteNationality", param)
            .then((response) => {
              getBraList();
            }).catch(err => {
              console.log(err);
            });
        }
      }
      const handleEdit = (id) => {
        axios.get(_SETTINGS_PATH + "/getNationality/" + id)
          .then((response) => {
            console.log("responseresponse", response)
            if (response.data.nationality) {
              setBraField(response.data.nationality.nation);
              setBraId(response.data.nationality.id);
              setOpenDialog(true);
            }
          }).catch(err => {
            console.log(err);
          });
      }
      const handleClearForm = () => {
        setBraField("");
        setBraId("");
        setOpenDialog(false);
      }
    
      return (
        <React.Fragment>
          <Header />
          {openDialog ?
    
            <Dialog open={openDialog} onClose={handleClearForm}>
              <DialogActions disableSpacing={true}>
                <IconButton className="close-pos close-span" onClick={e => { handleClearForm(false) }}><CloseIcon /></IconButton>
              </DialogActions>
              <DialogContent>
                <h4 className={"sethead"}>{braId ? "Update" : "Create"} Nationality</h4>
                <div style={{ minWidth: 275 }}>
                    <p><TextField id="outlined-basic" fullWidth={true} label="Nationality" variant="outlined" value={braField} onChange={handleBraInput} type="text" /></p>
                    <Button disabled={braField.length == 0}
                      onClick={handleSubmitForm} variant="contained">{braId ? "Update" : "Create"}</Button>
    
    
                  
    
                </div>
              </DialogContent>
            </Dialog>
    
            : null}
          <Container maxWidth="md" className="settings-container">
    
            <Grid container>
              <Grid item sm={8}>
                <h2 className={"filterhead"} >Nationality List</h2>
              </Grid>
              <Grid item sm={4} style={{textAlign:"right"}}>
                <Button variant='outlined' className="settingsnbtn" onClick={e => setOpenDialog(true)}>Add Nationality</Button>
              </Grid>
            </Grid>
    
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" className={"sett-table"}>
                <TableHead>
                  <TableRow>
                    <TableCell  padding={'none'}>Name</TableCell>
                    <TableCell align="right"  padding={'none'}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {braList.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell padding={'none'} >
                        {row.nation}
                      </TableCell>
                      <TableCell  padding={'none'} align="right">
                        <IconButton onClick={e => handleEdit(row.id)} aria-label="edit">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={e => handleDelete(row.id)} aria-label="delete">
                          <DeleteIcon />
                        </IconButton></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </React.Fragment>
    
    
      );
    }
    
export default Nationality;