import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from "@mui/material/InputAdornment";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import StarRatings from "react-star-ratings";
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { _BASE_URL } from "../../../Config/Constants";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
class AddResume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFormError:false,
            filterOptions: props.filterConfig,
            pmList:[],
            pmaList:[],
            editTemp:{},
            formValues: {
                branch: "",
                name: "",
                surName: "",
                dob: new Date(),
                phone: "",
                skype: "",
                alt_mobile: "",
                exp_education: "",
                exp_courses: "",
                exp_certificate: "",
                exp_experience: "",
                exp_profile: "",
                zipcode: "",
                emailPrivate: "",
                emailSupervision: "",
                nationality: "",
                residence: "",
                language: [],
                education: [],
                workField: [],
                proffesion: [],
                specialSkills: [],
                salary: "",
                knownBy: [],
                photo: "",
                resume: "",
                resumeTitle:"",
                isFreelance: false,
                hasEupassport: false,
                isVaccinated: false,
                isIntern: false,
                comment: [""],
                reference: [""],
                rating: 0,
                resumeList: [],
                knownOpt:[],
                father: "",
                mother: "",
                spouse: "",
                emergency_no: "",
                passport: "",
                passportExDate:" ",
            a1ExDate:" ",
            dateCreated:'',
            pm:" ",
            pma:" ",
            userId:this.getUserId()
            },
            refLen: 1,
            commentLen: 1,
            avatarURL: "",
            knownList:[],
            knowByLoading:false,
            uploadProgress:'',
            errorFields:[]
        }
        this.handleChangeField = this.handleChangeField.bind(this);
        this.handleResumeUpload = this.handleResumeUpload.bind(this);
        this.handlePostResume = this.handlePostResume.bind(this);
        this.setEditFields = this.setEditFields.bind(this);
        this.validateForm=this.validateForm.bind(this);
        this.closeModal=this.closeModal.bind(this);
        console.log(this.props.filterConfig)
    }
     getUserId = () => {
        let token = localStorage.getItem('token');
        const arr = JSON.parse(token);
        return arr['emp'];
    }
    validateForm(){
        let tempError=[];
        if(this.state.formValues.name==""){
            tempError.push('name');
        }
        if(this.state.formValues.surName==""){
            tempError.push('surName');
        }
        this.setState({errorFields:tempError,hasFormError:tempError.length});
        return tempError.length;
    }
    componentDidMount() {
        console.log("this.propsthis.props", this.props)
        if (this.props.editId) {
            this.setEditFields();
        }
    }
    setEditFields() {
        const { resumeData } = this.props;
        let pm = {
            value: parseInt(resumeData.pm),
            label: resumeData.pmName,
          };
          let pma = {
            value: parseInt(resumeData.pm),
            label: resumeData.pmaName,
          };
        let formValues = {
            branch: resumeData.branch,
            name: resumeData.name,
            surName: resumeData.surName,
            dob: new Date(resumeData.dob),
            phone: resumeData.phone,
            skype: resumeData.skype,
            alt_mobile: resumeData.alt_mobile,
            exp_education: resumeData.exp_education,
            exp_courses: resumeData.exp_courses,
            exp_certificate: resumeData.exp_certificate,
            exp_experience: resumeData.exp_experience,
            exp_profile: resumeData.exp_profile,
            zipcode: resumeData.zipcode,
            emailPrivate: resumeData.emailPrivate,
            emailSupervision: resumeData.emailSupervision,
            nationality: resumeData.nationality,
            residence: resumeData.residence,
            language: resumeData.languages ? resumeData.languages.map(item => item.id_lang) : [],
            education: resumeData.educations ? resumeData.educations.map(item => item.id_edu) : [],
            workField: resumeData.workFields ? resumeData.workFields.map(item => item.id_work_field) : [],
            proffesion: resumeData.proffesions ? resumeData.proffesions.map(item => item.id_proffesion) : [],
            specialSkills: resumeData.skills ? resumeData.skills.map(item => item.id_skill) : [],
            salary: resumeData.salary,
            knownBy: resumeData.knownBy,
            photo: resumeData.photo,
            resume: resumeData.resume,
            resumeTitle:resumeData.resumeTitle,
            isFreelance: resumeData.isFreelance == "Yes" ? true : false,
            hasEupassport: resumeData.hasEupassport == "Yes" ? true : false,
            isVaccinated: resumeData.isVaccinated == "Yes" ? true : false,
            isIntern: resumeData.isIntern == "Yes" ? true : false,
            comment: resumeData.comments ? resumeData.comments.map(item => item.comment) : [],
            reference: resumeData.references ? resumeData.references.map(item => item.reference) : [],
            resumeList: resumeData.resumeList ? resumeData.resumeList : [],
            rating: parseInt(resumeData.rating),
            knownOpt: resumeData.knowOpt ? resumeData.knowOpt : [],
            father: resumeData.father,
            mother: resumeData.mother,
            spouse: resumeData.spouse,
            emergency_no: resumeData.emergency_no,
            passport: resumeData.passport,
            passportExDate:resumeData.passportExDate?resumeData.passportExDate:" ",
            a1ExDate:resumeData.a1ExDate?resumeData.a1ExDate:" ",
            
            dateCreated:resumeData.dateCreated?resumeData.dateCreated:" ",
            pm:pm,
            pma:pma
        };
        let refLen = resumeData.references ? resumeData.references.length : 1;
        let commentLen = resumeData.comments ? resumeData.comments.length : 1;
        this.setState({ formValues: formValues,editTemp:formValues, refLen, commentLen, avatarURL: this.props.resumeData.img,pmList:[{ ...pm }],pmaList: [{ ...pma }]});
    }
    handleChangeField(value, field) {
        let formState = { ...this.state.formValues };
        formState[field] = value;
        this.setState({ formValues: formState });
    }
    handleChangeFieldMul(value, field, index) {
        let formState = { ...this.state.formValues };
        formState[field][index] = value;
        this.setState({ formValues: formState });
        console.log("formState", formState);
    }
    handleResumeUpload(fileType, e) {
        this.setState({uploadProgress:fileType});
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append(
            fileType,
            file,
            file.name
        );
        formData.append('docType', fileType);
        let _url = _BASE_URL + "/FrondEndController/uploadResume";
        if (fileType == 'photo')
            _url = _BASE_URL + "/FrondEndController/uploadPhoto"
        axios.post(_url, formData)
            .then(response => {
                const resData=response.data;
                this.setState({ uploadProgress:'' });
                if(typeof resData.uploaded_flleinfo !='undefined'){
                let formState = { ...this.state.formValues };
                formState[fileType] = response.data.uploaded_flleinfo.path;
                if (fileType == 'photo') {
                    this.setState({ formValues: formState, avatarURL: response.data.uploaded_flleinfo.url,uploadProgress:'' });
                }
                else if(fileType == 'refDoc') {
                    formState['resumeList'].push(response.data.uploaded_flleinfo.resume);
                    this.setState({ formValues: formState,uploadProgress:'' });
                }else{
                    formState['resume']=response.data.uploaded_flleinfo.resume.path;
                    formState['resumeTitle']=response.data.uploaded_flleinfo.resume.title;
                    
                    this.setState({ formValues: formState,uploadProgress:'' });
                }
            }else{
                console.log(response);
                alert(resData.errors[fileType]);
                 this.setState({ uploadProgress:'' });
            }
            }).catch((err) => {
                alert("error");
                console.log(err);
                this.setState({ uploadProgress:'' });
            });
    }
/* handlePostResume() {
        if(!this.validateForm()){
        let formState = { ...this.state.formValues };
        formState['empId'] = this.props.editId ? this.props.editId : 0;
        formState["pm"] = formState["pm"].value ? formState["pm"].value : 0;
        formState["pma"] = formState["pma"].value ? formState["pma"].value : 0;
        // api if ipo cheyyam open api empId!=0 edit or insert
        axios.post(_BASE_URL + "/FrondEndController/addResume", formState)
            .then(response => {
                if (this.props.refreshResume)
                    this.props.refreshResume();
                alert(response.data.msg);
            }).catch((err) => {
                alert("error");
                console.log(err);
            });
        }
    } */
    handlePostResume() {
        if (!this.validateForm()) {
            let formState = { ...this.state.formValues };
            formState['empId'] = this.props.editId ? this.props.editId : 0;
            formState["pm"] = formState["pm"].value ? formState["pm"].value : 0;
            formState["pma"] = formState["pma"].value ? formState["pma"].value : 0;
    if(formState['empId']){
        this.submitResume(formState);
    }else{
            axios.post(_BASE_URL + "/FrondEndController/checkDuplicateResume", {
                name: formState.name,
                surName: formState.surName,
                nationality: formState.nationality,
                empId:formState['empId']
            })
            .then(response => {
                if (response.data.exists) {
                    if (window.confirm(`${response.data.count} resumes already exist with this name. Are you sure you want to add this one?`)) {
                        this.submitResume(formState);
                    }
                } else {
                    this.submitResume(formState);
                }
            })
            .catch(err => {
                alert("Error checking for duplicates");
                console.log(err);
            });
        }
        }
    }
    
    submitResume(formState) {
        axios.post(_BASE_URL + "/FrondEndController/addResume", formState)
            .then(response => {
                if (this.props.refreshResume)
                    this.props.refreshResume();
                alert(response.data.msg);
            })
            .catch((err) => {
                alert("Error");
                console.log(err);
            });
    }
    

    onDelResume(index) {
        let formState = { ...this.state.formValues };
        let resumeList = [...formState.resumeList];
        resumeList.splice(index, 1);
        formState['resumeList'] = resumeList;
        this.setState({ formValues: formState });
    }
    handleKnownBy=(e)=>{
        console.log("Test",e.target.value);
        //knowByLoading
        if(e.target.value&& e.target.value.length>0){
            this.setState({knowByLoading:true});
        axios.post(_BASE_URL + "/FrondEndController/fetchKnownMem", {q:e.target.value})
            .then(response => {
              console.log(response);
              this.setState({knownList:response.data.empList})

            }).catch((err) => {
                alert("error");
                console.log(err);
            });
        }
    }
    changeKnownBy=(event, values)=>{
        console.log(values);
        let formState = { ...this.state.formValues };
        formState['knownOpt'] = values;
        this.setState({ formValues: formState });
    }
    closeModal=()=>{
       let empId=this.props.editId ? this.props.editId : 0;
       if(empId){
           if(this.state.formValues != this.state.editTemp){
               console.log("this.state.formValues",this.state.formValues);               
               console.log("this.state.editTemp",this.state.editTemp);
               Object.keys(this.state.formValues).forEach((item)=>{
                    console.log("item",item)
               });
               if(window.confirm("Changes will be lost")){
                this.props.closeAddResume();
               }
           }else{
            this.props.closeAddResume();
           }
       }else{
        this.props.closeAddResume();
       }
    }
     fetchPmList = (e, item) => {
        console.log(e.target.value);
        axios
          .get(_BASE_URL + "/UserController/getUsers?search=" + e.target.value)
          .then((response) => {
            let cl = response.data.users.map((data) => ({
              value: parseInt(data.id),
              label: data.firstname + " " + data.lastname,
            }));
            if (item == "pm") this.setState({pmList:cl});
            else this.setState({pmaList:cl});
          })
          .catch((err) => {
            console.log(err);
          });
      };
    render() {
        const { filterConfig, editId } = this.props;
        console.log("filterConfigfilterConfigfilterConfig",filterConfig)
        return (
            <React.Fragment>

                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={this.props.openAddResume}
                    onClose={this.closeModal}
                    scroll={"body"}
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item md={11} className={"filterhead"}><strong>{editId ? "Edit" : "Add"} Resume</strong></Grid>

                            <Grid item md={1} style={{textAlign:"right"}}>  <IconButton type="button"   className="close-pos close-span"sx={{ p: '10px' }} onClick={this.closeModal} aria-label="search">
                                <CloseIcon  />

                            </IconButton></Grid>
                            <Grid item md={12}>       <hr /></Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={"form-dialog"}>
                        <br />
                        <Box
                            component="form"
                            sx={{ flexGrow: 1 }}
                        >
                            <Grid container spacing={3}>
                                <Grid item md={8}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>

                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Branch</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.branch}
                                                    label="Branch"
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'branch');
                                                    }}
                                                >

                                                    {filterConfig && filterConfig.branches.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.branch}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Name"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.name}
                                                size="small"
                                            error={this.state.errorFields.indexOf('name')!==-1}
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'name');
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Surname"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.surName}
                                                error={this.state.errorFields.indexOf('surName')!==-1}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'surName');
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
      views={['year']}
      size="small"
      label="Year of birth"
      className={"yearPick-box" }
      value={this.state.formValues.dob}
        onChange={(newValue) => {
            console.log(newValue);
            this.handleChangeField(newValue, 'dob');
        }}
        renderInput={(params) => <TextField style={{width: "100%"}} className="yearPick" size="small" {...params} />}
        maxDate={new Date()}
      />
    </LocalizationProvider>
{/*      
<LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                views={['year']}
                                                    size="small"
                                                    label="Year of birth"
                                                    value={this.state.formValues.dob}
                                                    defaultValue={new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()}
                                                    onChange={(newValue) => {
                                                        console.log(newValue);
                                                        this.handleChangeField(newValue, 'dob');
                                                    }}
                                                    renderInput={(params) => <TextField style={{width: "100%"}} size="small" {...params} />}
                                                    maxDate={new Date()}
                                                    style={{width: "100%"}}
                                                />
                                            </LocalizationProvider> */}
                                        
                                        </Grid>

                                        <Grid item md={6}>

                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Job Title</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.proffesion}
                                                    label="Proffesions"
                                                    multiple
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'proffesion');
                                                    }}
                                                >
                                                    {filterConfig && filterConfig.proffesions.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.proffesion}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Phone"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.phone}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'phone');
                                                }}
                                            />
                                        </Grid>
                                        
                                  
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Alternative phone"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.alt_mobile}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'alt_mobile');
                                                }}
                                            />
                                        </Grid>

                                       
                                        </Grid>
                                        <Grid container spacing={3}>
                                        {/* <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Skype ID"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.skype}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'skype');
                                                }}
                                            />
                                        </Grid> */}
                                   
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Email ( Private)"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.emailPrivate}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'emailPrivate');
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Email ( Supervision)"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.emailSupervision}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'emailSupervision');
                                                }}
                                            />
                                        </Grid>
                                        </Grid>
                                    <Grid container spacing={3}>
                                       
                                   
                                        <Grid item md={6}>

                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Nationality</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.nationality}
                                                    label="Nationality"
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'nationality');
                                                    }}
                                                >

                                                    {filterConfig && filterConfig.nationalities.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.nation}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                        <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Place of Residence</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.residence}
                                                    label="Place of Residence"
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'residence');
                                                    }}
                                                >

                                                    {filterConfig && filterConfig.residences.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.residence}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                        </Grid>
                                    <Grid container spacing={3}>
                                        
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Postal Code"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.zipcode}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'zipcode');
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6}>

                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Languages</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.language}
                                                    label="Languages"
                                                    multiple
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'language');
                                                    }}
                                                >
                                                    {filterConfig && filterConfig.languages.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.language}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        </Grid>
                                    <Grid container spacing={3}>
                                     
                                        <Grid item md={6}>
                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Education</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.education}
                                                    label="Education"
                                                    multiple
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'education');
                                                    }}
                                                >

                                                    {filterConfig && filterConfig.educations.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.education}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    
                                        <Grid item md={6}>

                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Field of work</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.workField}
                                                    label="Field of work"
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'workField');
                                                    }}
                                                    multiple
                                                >
                                                    {filterConfig && filterConfig.workFields.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.work}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Special skills</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.specialSkills}
                                                    label="Skills"
                                                    multiple
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'specialSkills');
                                                    }}
                                                >

                                                    {filterConfig && filterConfig.skills.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.skill}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Salary ( Rate)"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.salary}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'salary');
                                                }}
                                            />
                                        </Grid>
                                        </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item md={6}>
                                        <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size={"small"}
                                                    options={this.state.pmList}
                                                    value={this.state.formValues.pm}
                                                    getOptionLabel={(option) => {
                                                    return option.label || "";
                                                    }}
                                                    renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e) => this.fetchPmList(e, "pm")}
                                                        label="Project Manager"
                                                        placeholder="Search  Project Manager"
                                                    />
                                                    )}
                                                    onChange={(e, newValue) => {
                                                    this.handleChangeField(newValue, 'pm');
                                                    }}
                                                />
                                        </Grid>
                                        <Grid item md={6}>
                                        {/* <FormControl size="small" fullWidth sx={{ minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Known By</InputLabel>
                                        <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.formValues.knownOpt}
                                                    label="Branch"
                                                    multiple
                                                    onChange={(e) => {
                                                        this.handleChangeField(e.target.value, 'knownOpt');
                                                    }}
                                                >

                                                    {filterConfig && filterConfig.knownbys.map((item) => (
                                                        <MenuItem value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl> */}
                                            {/* <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                options={this.state.knownList}
                                                getOptionLabel={(option) => (option.name)}
                                                defaultValue={this.state.formValues.knownOpt}
                                                onChange={this.changeKnownBy}                                                
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        variant="outlined"
                                                        label="Known By"
                                                        placeholder="Members"
                                                        onChange={this.handleKnownBy}
                                                    />
                                                )}
                                            /> */}
                                           </Grid>
                                    </Grid>
                                         
{/* 
                                    <Grid container spacing={3}>
                                                <Grid item md={6} >
                                                <TextField fullWidth
                                                label="Profile"
                                                multiline
                                                    maxRows={4}
                                                defaultValue={this.state.formValues.exp_profile}
                                              
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'exp_profile');
                                                }}
                                                />
                                                </Grid>
                                                <Grid item md={6}  >
                                                <TextField fullWidth
                                                label="Experience"
                                                multiline
                                                    maxRows={4}
                                                defaultValue={this.state.formValues.exp_experience}
                                              
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'exp_experience');
                                                }}
                                                />
                                                </Grid>


                                            </Grid> */}

                                            {/* <Grid container spacing={3}>
                                                <Grid item md={6} >
                                                <TextField fullWidth
                                                label="Education"
                                                multiline
                                                    maxRows={4}
                                                defaultValue={this.state.formValues.exp_education}
                                              
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'exp_education');
                                                }}
                                                />
                                                </Grid>
                                                <Grid item md={6}  >
                                                <TextField fullWidth
                                                label="Courses and certificates"
                                                multiline
                                                    maxRows={4}
                                                defaultValue={this.state.formValues.exp_courses}
                                              
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'exp_courses');
                                                }}
                                                />
                                                </Grid>


                                            </Grid> */}
                                            <Grid container spacing={3}>

                                           
                                        {/* <Grid item md={6}>
                                        <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size={"small"}
                                                    options={this.state.pmList}
                                                    value={this.state.formValues.pma}
                                                    getOptionLabel={(option) => {
                                                    return option.label || "";
                                                    }}
                                                    renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e) => this.fetchPmList(e, "pma")}
                                                        label="Project Coordinator"
                                                        placeholder="Search  Project Coordinator"
                                                    />
                                                    )}
                                                    onChange={(e, newValue) => {
                                                    this.handleChangeField(newValue, 'pma');
                                                    }}
                                                />
                                        </Grid> */}

                            </Grid>

                                    {/* <Grid container spacing={3}>
                                    <Grid  item md={12} className="perhead"><strong>Personal Details <hr /></strong></ Grid >
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Father's Name"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.father}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'father');
                                                }}
                                            />
                                        </Grid>
                                        
                                  
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Mother's Name"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.mother}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'mother');
                                                }}
                                            />
                                        </Grid>                                      
                                        </Grid>      */}

                                        {/* <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Spouse's Name"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.spouse}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'spouse');
                                                }}
                                            />
                                        </Grid>
                                        
                                  
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Emergency Contact Number"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.emergency_no}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'emergency_no');
                                                }}
                                            />
                                        </Grid>                                      
                                        </Grid>  

                                        <Grid container spacing={3}>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Passport Info"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.passport}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'passport');
                                                }}
                                            />
                                        </Grid>
 
                                        
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="Passport Expiry"
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.passportExDate?this.state.formValues.passportExDate:" "}
                                                InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                  }}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'passportExDate');
                                                }}
                                                type="date"

                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField fullWidth
                                                label="A1 Expiry "
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.a1ExDate?this.state.formValues.a1ExDate:" "}
                                                InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                  }}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'a1ExDate');
                                                }}
                                                type="date"
                                            />
                                        </Grid>
                                        
                                                                  
                                        </Grid>  */}

                                      
                                    <Grid container spacing={3}>
                                        <Grid item md={2.8}>
                                            <label>Upload Profile Photo</label><br/>
                                            <label className="upd-btn">SELECT FILE 
                                                <input className={"hide"} type={"file"} onChange={(e) => this.handleResumeUpload('photo', e)} />
                                            </label> {this.state.uploadProgress=='photo'?<CircularProgress color="success" size={"14px"}/>:null}
                                            {this.state.avatarURL ?
                                                <div><img src={this.state.avatarURL} width="64px" /></div> : null}
                                        </Grid>
                                        <Grid item md={2.5}>
                                            <label>Upload Resume</label><br/>
                                            {/* {this.state.formValues.resume} ok?yes edit work alla api change undu */}
                                            <label className="upd-btn">SELECT FILE
                                                <input type={"file"} className={"hide"} onChange={(e) => this.handleResumeUpload('resume', e)} />
                                            </label> {this.state.uploadProgress=='resume'?<CircularProgress color="success" size={"14px"}/>:null}
                                            {(this.state.formValues.resumeTitle ) ?
                                                <div>{this.state.formValues.resumeTitle}</div> : null}
                                        </Grid>
                                        <Grid item md={2.5}>
                                            <label>Upload Ref Docs</label><br/>
                                            {/* {this.state.formValues.resume} ok?yes edit work alla api change undu */}
                                            <label className="upd-btn">SELECT FILE
                                                <input type={"file"} className={"hide"} onChange={(e) => this.handleResumeUpload('refDoc', e)} />
                                            </label>
                                            {this.state.uploadProgress=='refDoc'?<CircularProgress color="success" size={"14px"}/>:null}
                                            {(this.state.formValues.resumeList && this.state.formValues.resumeList.length) ?
                                                <div>{this.state.formValues.resumeList.map((res, index) => (
                                                    <Chip label={res.title} key={'resId' + index} onDelete={e => { this.onDelResume(index) }} />
                                                ))}</div> : null}
                                        </Grid>
                                        <Grid item md={4} sx={{mt:3}}>
                                            <TextField fullWidth
                                                label="Date created "
                                                id="outlined-size-small"
                                                defaultValue={this.state.formValues.dateCreated?this.state.formValues.dateCreated:" "}
                                                InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                  }}
                                                size="small"
                                                onChange={(e) => {
                                                    this.handleChangeField(e.target.value, 'dateCreated');
                                                }}
                                                type="date"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <FormControlLabel
                                                label="Freelance"
                                                control={
                                                    <Checkbox
                                                        checked={this.state.formValues.isFreelance}
                                                        onChange={(e) => {
                                                            if(this.state.formValues.isFreelance)
                                                            this.handleChangeField(false, 'isFreelance');
                                                            else
                                                            this.handleChangeField(true, 'isFreelance');
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item >
                                            <FormControlLabel
                                                label="European passport"
                                                control={
                                                    <Checkbox
                                                        checked={this.state.formValues.hasEupassport}
                                                        onChange={(e) => {                                                           
                                                            if(this.state.formValues.hasEupassport)
                                                            this.handleChangeField(false, 'hasEupassport');
                                                            else
                                                            this.handleChangeField(true, 'hasEupassport');
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item >
                                            <FormControlLabel
                                                label="Interview"
                                                control={
                                                    <Checkbox
                                                        checked={this.state.formValues.isVaccinated}
                                                        onChange={(e) => {
                                                            if(this.state.formValues.isVaccinated)
                                                            this.handleChangeField(false, 'isVaccinated');
                                                            else
                                                            this.handleChangeField(true, 'isVaccinated');
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid item >
                                            <FormControlLabel
                                                label="Intern"
                                                control={
                                                    <Checkbox
                                                        checked={this.state.formValues.isIntern}
                                                        onChange={(e) => {
                                                            if(this.state.formValues.isIntern)
                                                            this.handleChangeField(false, 'isIntern');
                                                            else
                                                            this.handleChangeField(true, 'isIntern');
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item md={4} spacing={3}>
                                    <Grid container>
                                        {[...Array(this.state.refLen)].map((x, i) =>
                                            <Grid item md={12} key={x}>
                                                <TextField fullWidth
                                                    id="outlined-multiline-flexible"
                                                    label="Reference"
                                                    multiline
                                                    maxRows={4}
                                                    value={(this.state.formValues.reference[i] ? this.state.formValues.reference[i] : "")}
                                                    onChange={(e) => {
                                                        this.handleChangeFieldMul(e.target.value, 'reference', i);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        <label className={"vpuplecor"} onClick={(e) => { this.setState({ refLen: (this.state.refLen + 1) }) }}><AddIcon className={"addicon"} /> Add Another Reference</label>
                                        <br />
                                        <br />
                                    </Grid>
                                    <Grid container style={{ marginTop: "20px" }}>
                                        {!this.props.editId&&[...Array(this.state.commentLen)].map((x, i) =>
                                        <React.Fragment>
                                            <Grid item md={12} key={x}>
                                                <TextField fullWidth
                                                    id="outlined-multiline-flexible"
                                                    label="Other Information"
                                                    multiline
                                                    maxRows={4}
                                                    value={(this.state.formValues.comment[i] ? this.state.formValues.comment[i] : "")}
                                                    onChange={(e) => {
                                                        this.handleChangeFieldMul(e.target.value, 'comment', i);
                                                    }}
                                                />
                                            </Grid>
                                           
                                            </React.Fragment>
                                        )} 
                                        {!this.props.editId&& <label className={"vpuplecor"} onClick={(e) => { this.setState({ commentLen: (this.state.commentLen + 1) }) }}><AddIcon className={"addicon"} /> Add Another Comment</label>}
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <br />
                                            <br />
                                            <label>Rating</label>
                                            <br />
                                            <StarRatings
                                                rating={this.state.formValues.rating}
                                                starRatedColor="#e7af00"
                                                changeRating={(newrating) => {
                                                    this.handleChangeField(newrating, 'rating');
                                                }}
                                                numberOfStars={5}
                                                starDimension={"25px"}
                                                starSpacing={"5px"}
                                                starHoverColor={"yellow"}
                                                name='rating' />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item md={12} style={{ textAlign: "center" }}>
                                        <Button className={"viewresbtn"} variant="outlined" type="button" onClick={this.handlePostResume}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose}>Close</Button> */}
                    </DialogActions>
                </Dialog>
            </React.Fragment>)
    }

}

export default AddResume;