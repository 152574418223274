import React, { useState } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { _TRIP_PATH } from "../../Config/Constants";
import axios from "axios";
import Checkbox from '@mui/material/Checkbox';

const ReminderTrip = ({singleTrip}) => {
  const [reminderForm, setReminderForm] = useState({ title: "", message: "" });
  const [reminderList, setReminderList] = useState([]);
  const [reminderEmails,setReminderEmails] = useState({
    privateEmail:'',
    companyEmail:''
  })
  React.useEffect(()=>{
    const getRemiders = () => {
        axios.get(`${_TRIP_PATH}/getReminder`).then((result) => {
          setReminderList([...result.data.reminderList]);
          
        });
      };
      getRemiders();

  },[])
  React.useEffect(()=>{
    // console.log("singleTripsingleTrip",singleTrip)
    if(singleTrip.emailPrivate !== ""){
      let temp={...reminderEmails};
      temp["privateEmail"] = singleTrip.emailPrivate;
      console.log("privateEmailprivateEmail",temp);
      setReminderEmails(temp)
     }
   },[singleTrip])
    
  const handleReminderSave = (action) => {

    const payload = { ...reminderForm,action };
    if(action == "save"){
        axios
          .post(`${_TRIP_PATH}/addReminder`, payload)
          .then((response) => {
            setReminderForm({ title: "", message: "" })
            setReminderList(response.data.reminders);
          })
          .catch(() => {
            alert("Error");
          });
          }
          else
            {
              const payload = { ...reminderForm, ...reminderEmails,action };
   
        axios
          .post(`${_TRIP_PATH}/sendReminder`, payload)
          // .then((response) => {
          //   console.log("response from send reminde",response.data)
          //   setReminderForm({ title: "", message: "" })
          //   setReminderList(response.data.reminders);
          // })
          // .catch(() => {
          //   alert("Error");
          // });
           .then((response) => {
    console.log("Response from sendReminder:", response.data);
    if (response.data && response.data.status === 'success') {
      alert(response.data.message); // Display success message
      // Additional handling if needed
    } else {
      alert("Failed to send reminder."); // Display error message
    }
  })
  .catch((error) => {
    console.error("Error occurred during API call:", error);
    alert("Error occurred during API call. Please try again later.");
  });

          }

  };

  
  const deleteReminder = (rem) => {
    const payload = { id: rem.id };
    if (window.confirm("Do you want to delete this reminder?")) {
      axios
        .post(`${_TRIP_PATH}/deleteReminder`, payload)
        .then((response) => {setReminderForm({ title: "", message: "" })
          setReminderList(response.data.reminders);
        })
        .catch(() => {
          alert("Error");
        });
    }
  };
  const handleValue = (e, key) => {
    let temp = { ...reminderForm };
    temp[key] = e.target.value;
    setReminderForm(temp);
  };
  const handleEmailValue =(value,key)=>{
    let temp = {...reminderEmails};
      if (temp[key] === "") {
        temp[key] = value;
    }
    else
    {
         temp[key] = '';
 
    }
    console.log("temp emails",temp)
    setReminderEmails(temp);
  }
  return (
    <>
      <Grid container>
        <Grid item sm={8}>
          <h3>Reminders</h3>
        </Grid>
        <Grid>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={(e) => {
              setReminderForm({ title: "", message: "" });
            }}
            small={"small"}
          >
            <AddBoxIcon />
          </IconButton>
        </Grid>
      </Grid>
      <List>
        {reminderList.map((rem) => (
          <ListItem
          style={{cursor:"pointer"}}
            key={rem.id}
            onClick={(e) => {
              setReminderForm({ ...rem });
            }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteReminder(rem);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={rem.title} />
          </ListItem>
        ))}
      </List>
      <div>
        <TextField
          required
          label="Subject"
          value={reminderForm.title}
          size={"small"}
          fullWidth={true}
          onChange={(e) => handleValue(e, "title")}
        />
      </div>
      <br />
      <div>
        <TextField
          required
          label="Content"
          value={reminderForm.message}
          multiline={true}
          fullWidth={true}
          onChange={(e) => handleValue(e, "message")}
          rows={5}
        />
      </div>
      <br />
{singleTrip.emailPrivate !== ""  || singleTrip.emailSupervision !== "" ?
       <Grid container spacing={1}>
    {  singleTrip.emailPrivate !== "" &&
      <Grid item>
             <p >                               
    
          <Checkbox 
          checked={reminderEmails.privateEmail !== ""}
           onChange={(e) =>handleEmailValue(singleTrip.emailPrivate,"privateEmail" )}/>
                {singleTrip.emailPrivate}</p>
          
          </Grid>
          }
{singleTrip.emailSupervision !== "" &&
      <Grid item>
         <p >                               

      <Checkbox 
      checked={reminderEmails.companyEmail !== ""}
       onChange={(e) =>handleEmailValue(singleTrip.emailSupervision,"companyEmail" )}/>
            {singleTrip.emailSupervision}</p>
      
      </Grid>
      }
      </Grid>:<div>No emails found </div>
      }

      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color={"primary"}
          onClick={() => handleReminderSave("save")}
                    disabled={!(reminderForm.title !== "" && reminderForm.message )}

        >
          {reminderForm.id ? "Update" : "Create"}
        </Button>
        <Button
          variant="contained"
          color={"primary"}
          style={{ marginLeft: "20px" }}
          onClick={() => handleReminderSave("send")}
           disabled={!(reminderForm.title !== "" && reminderForm.message!== "" &&
           (reminderEmails.companyEmail !== "" || reminderEmails.privateEmail !== ""))}
        >
          Send
        </Button>
      </div>
      
    </>
  );
};
export default ReminderTrip;
