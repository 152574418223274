import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import StarRatings from "react-star-ratings";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { _BASE_URL } from "../../../Config/Constants";
import CommentList from "../CommentList";
import Highlighter from "react-highlight-words";
const getProfCombained = (proffesion) => {
    const arr = proffesion.map((item) => item.proffesion);
    return arr.join(',');
}
const getEduCombained = (edu) => {
    const arr = edu.map((item) => item.education);
    return arr.join(',');
}
const getWorkCombained = (workFields) => {
    const arr = workFields.map((item) => item.work);
    return arr.join(',');
}
const getLangCombained = (langField) => {
    const arr = langField.map((item) => item.language);
    return arr.join(',');
}
const getSkillsCombained = (skills) => {
    const arr = skills.map((item) => item.skill);
    return arr.join(',');
}
const getKnownCombained = (refFields) => {
    const arr = refFields.map((item) => item.name);
    return arr.join(',');
}
class ViewResumeSummary extends Component {
    constructor(props) {
        super(props);
        this.state = { commentText: "",tab:1 };
        this.downloadResume = this.downloadResume.bind(this);
        this.handleCommentAdd = this.handleCommentAdd.bind(this);
        this.downloadRefDoc = this.downloadRefDoc.bind(this);
        this.savePdf = this.savePdf.bind(this);
    }
    downloadResume() {
        console.log(this.props.resume.resumeFile);
        window.open(_BASE_URL + "/" + this.props.resume.resume).focus();
    }
    downloadRefDoc(doc) {
        window.open(_BASE_URL + "/" + doc.path).focus();
    }
    handleCommentAdd() {
        const params = { comment: this.state.commentText, emp_id: this.props.resume.id };
        axios.post(_BASE_URL + '/add-comment', params).
            then((response) => {
                if (response.data.status == "success") {
                    this.setState({ "commentText": "" });
                    this.props.handleViewResume(this.props.resume.id);
                }

            })
            .catch(err => {
                alert("Error failed to add comments");
            })
    }
    savePdf() {
        window.open(_BASE_URL + '/FrondEndController/responsePDF/' + this.props.resume.id).focus();
    }
    render() {
        const { resume, searchTerm,searchArray } = this.props;
        const {tab} =this.state;
        return (
            <div className="summary-viewbox">
                {(resume && typeof resume.name != 'undefined') ?
                    <React.Fragment>
                         <Grid container>
                            <Grid item md={12} ><strong>View Employee Details</strong></Grid>
                        </Grid> 
                        <Grid container className="">
                            <Grid item sm={12} style={{marginBottom:"5px"}}>
                                <span className={"tabItem "+(tab==1?"_selected_tab _tr_cstab":"_tr_cstab")} onClick={()=>this.setState({tab:1})}>Proffesional </span>
                                <span className={"tabItem "+(tab==2?"_selected_tab _tr_cstab":"_tr_cstab")}  onClick={()=>this.setState({tab:2})}> Personal </span>
                            </Grid>
                        </Grid>
                        {tab==1?<Grid container className={"scollabel "}>
                        <Grid item md={12} className="print-sm2">
                            <div style={{textAlign:"center"}}>
                                <img src={resume.img} width={68} />
                                <br />
                                <StarRatings
                                    rating={parseInt(resume.rating)}
                                    starRatedColor="#e7af00"
                                    numberOfStars={5}
                                    starDimension={"15px"}
                                    starSpacing={"5px"}
                                    starHoverColor={"yellow"}
                                    name='rating' />
</div>
      
                                <br />
                                {resume.resume != "" ?
                                    <Button size="small" className={"viewresbtn hide-print"} onClick={this.downloadResume} startIcon={<AttachFileIcon />} variant="outlined">Resume </Button>
                                    : null}

                                {resume.resumeList != [] ?
                                    resume.resumeList.map((refDoc) =>
                                        <p className="p-chip" onClick={e => this.downloadRefDoc(refDoc)}>{refDoc.title}</p>
                                    ) : null}

                            </Grid>
                            <Grid item md={12} className="print-sm1 containerDiv">
                                <Grid container >
                                    <Grid item md={12}><p className="phead">Branch  : <strong>{resume.branch_res ? resume.branch_res.branch : ''}</strong></p>
                                    <p className="phead">Name  : <strong> {resume.name + " " + resume.surName}</strong></p>
                                    {resume.dob ?
                                        <p className="phead">Year of birth  : <strong>{resume.dob.split("-")[0]}</strong></p>
                                        : null}
                                    <p className="phead">Phone  : <strong>{resume.phone}</strong></p>
                                    {resume.alt_mobile ?
                                        <p className="phead">Alternative Phone: <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.alt_mobile} /></strong></p>
                                        : null}

                                    {resume.skype ?
                                        <p className="phead">Skype Id  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.skype} /></strong></p>
                                        : null}
                                    {resume.emailPrivate ?
                                        <p className="phead">Email (Private)  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.emailPrivate} /></strong></p>
                                        : null}
                                    {resume.emailSupervision ?
                                        <p className="phead">Email (Supervision)  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.emailSupervision} /></strong></p>
                                        : null}
                                    {resume.nation ?
                                        <p className="phead">Nationality  : <strong>{resume.nation ? <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.nation.nation} /> : ''}</strong></p>
                                        : null}
                                    {resume.residence ?
                                        <p className="phead">Place of Residence  : <strong>{resume.residences ? <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.residences.residence} /> : ""}</strong></p>
                                        : null}
                                    {resume.zipcode ?
                                        <p className="phead">Full address: <strong>{resume.zipcode}</strong></p>
                                        : null}
{resume.languages ? 
<p className="phead">Language  : <strong>
    <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getLangCombained(resume.languages)} />
                                         </strong></p>: null}
                                         {resume.educations ? 
                                    <p className="phead">Education  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getEduCombained(resume.educations)} /></strong></p>
                                        : null}
{resume.proffesions ?
<p className="phead">Profession  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getProfCombained(resume.proffesions)} /> </strong></p>
                                         : null}
{resume.workFields ?
<p className="phead">Field of Work  : <strong>
<Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getWorkCombained(resume.workFields)} /> </strong></p>
                                        :null}
{resume.skills ?
                                    <p className="phead">Special Skills  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getSkillsCombained(resume.skills)} /></strong></p>
                                        :null}
{resume.salary ?
<p className="phead">Salary (Rate)  : <strong>{resume.salary}</strong></p>:null}
{resume.refFields ?
<p className="phead">Known By : <strong>{resume.refFields ? getKnownCombained(resume.refFields) : ''}</strong></p>
:null}</Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.isFreelance == 'Yes'} />}
                                                    label="Freelance"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.hasEupassport == 'Yes'} />}
                                                    label="European passport"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.isVaccinated == 'Yes'} />}
                                                    label="Vaccinated"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.isIntern == 'Yes'} />}
                                                    label="Intern"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </Grid>
                            


                            {resume.exp_experience ?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Experience</h4>

                                    <div className={"vpuplecor"} style={{ whiteSpace: 'pre-wrap' }}  >
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_experience} />
                                    </div>


                                </Grid>
                                : null}

                            {resume.exp_education?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Education</h4>
                                    <div className={"vpuplecor"} style={{ whiteSpace: 'pre-wrap' }}>
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_education} />
                                    </div>

                                </Grid>
                                : null}

                            {resume.exp_courses?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Courses and certificates</h4>
                                    <div className={"vpuplecor"} style={{ whiteSpace: 'pre-wrap' }}>
                                      
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_courses} />
                                    </div>
                                </Grid>
                                : null}


                            {resume.exp_profile ?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Profile</h4>
                                    <p className={"vpuplecor"}>
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_profile} />
                                    </p>

                                </Grid>
                                : null}


{resume.references &&<Grid item md={12} className={"list-sub-container"}>

                                <h4 className="sub-h4">Reference</h4>
                                {resume.references && resume.references.map((item) =>
                                    <p className={"vpuplecor"}>
                                        {item.reference}
                                    </p>
                                )}

                            </Grid>}

                        </Grid>
                        :<>
                        
                        {resume.father ?
<Grid item md={12}>Father  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={resume.father} /></strong></Grid>
                                        :null}

                        {resume.mother ?
                        <Grid item md={12}>Mother  : <strong> {resume.mother}</strong></Grid>
                        :null}
                        {resume.spouse ?
                        <Grid item md={12}>spouse  : <strong> {resume.spouse}</strong></Grid>
                        :null}
                        {resume.emergency_no ?
                        <Grid item md={12}>Emergency Contact Number  : <strong> {resume.emergency_no}</strong></Grid>
                        :null}
                        {resume.passport ?
                        <Grid item md={12}>Passport Details  : <strong> {resume.passport} </strong></Grid>
                        :null}
                        {resume.passportExDate ?
                       <Grid item md={12}>Passport Expiry Date: <strong> {resume.passportExDate}</strong></Grid>
                        :null}
                        {resume.a1ExDate ?
                        <Grid item md={12}>A1 Expiry Date : <strong> {resume.a1ExDate}</strong></Grid>
                        :null}


                                                                                                        </>
                        }
                    </React.Fragment>
                    :
                    <div style={{ minHeight: '300px', textAlign: 'center', paddingTop: '10%', width: '100%' }}>
                        <CircularProgress />
                    </div>
                }
       </div> )
    }
    
}
export default ViewResumeSummary;