import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../Includes/Header";
import { _TRIP_PATH } from "../../Config/Constants";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClientMember from "./ClientMember";
import "./client.css";
import { textAlign } from "@mui/system";
const columns = [
  {
    label: "Client Name",
    key: "client",
  }, {
    label: "Address",
    key: "address",
  }, {
    label: "No of Persons",
    key: "memCount",
  },{
    label: "Invoice at Latest",
    key: "invoice",
  }]
const Client = (props) => {
  const [clientField, setClientField] = React.useState("");
  const [singleClient, setSingleClient] = React.useState({});
  const [clientId, setClientId] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [invoice, setInvoice] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [clientList, setClientList] = React.useState([]);
  const [isForm, setIsForm] = React.useState(false);
  const [searchtext, setSearchtext] = React.useState([]);
  const [searchOpen,setSearchOpen]=React.useState(false);
    const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("client");

  React.useEffect(() => {
    getClientList();
  }, []);
  const handleTypeInput = (e) => {
    setClientField(e.target.value);
  };

  const handleSubmitForm = () => {
    const param = { client: clientField, address: address, id: clientId ,comments:comments,invoice:invoice};
    axios
      .post(_TRIP_PATH + "/addClient", param)
      .then((response) => {
        getClientList();
        handleClearForm();
        alert(response.data.msg)
        setOpenDialog(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getClientList = () => {
    axios
      .get(_TRIP_PATH + "/getClient")
      .then((response) => {
        console.log("responseresponse", response);
        setClientList(response.data.clientList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id };
      axios
        .post(_TRIP_PATH + "/deleteClient", param)
        .then((response) => { 
          if(response.data.status=='error'){
            alert(response.data.msg)
          }
          else
          getClientList();
          setOpenDialog(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleEdit = (id) => {
    axios
      .get(_TRIP_PATH + "/getClient/" + id)
      .then((response) => {
        console.log("responseresponse", response);
        if (response.data.client) {
          setClientField(response.data.client.client);
          setAddress(response.data.client.address);
          setComments(response.data.client.comments);
          setInvoice(response.data.client.invoice);
          setClientId(response.data.client.id);
          setOpenDialog(true);
          setSingleClient(response.data);
          getClientList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClearForm = () => {
    setClientField("");
    setAddress("");
    setClientId("");
    setInvoice("");
    setComments("");

  };
    const setSortFields = (data) => {
    //setOrderBy(order)
    let tempOrder = order;
    if (orderBy == data) tempOrder = order == "asc" ? "desc" : "asc";
    else tempOrder = "asc";
    setOrderBy(data);
    setOrder(tempOrder);
  };
  const getTableRows = () => {
    return clientList.filter(it=>it.client.includes(searchtext)).sort((a,b)=>{
      if(order=='asc')
           return a[orderBy].localeCompare(b[orderBy])
      return b[orderBy].localeCompare(a[orderBy])
    });
  };
  return (
    <React.Fragment>
      <Header />
      <Container
        maxWidth="100%"
        className="settings-container"
        style={{ marginTop: "70px" }}
      >
        <Grid container>
          <Grid item sm={8}>
            <h3 className={"pagehead"}>Client Management</h3>
          </Grid>
          <Grid item sm={4} style={{ textAlign: "right" }}>
          {searchOpen&&<input type="text" onChange={e=>setSearchtext(e.target.value)} placeholder="Search" className="searchCSBox" style={{ }} />}
            <IconButton
              variant="outlined"
              onClick={(e) =>{ setSearchtext("");setSearchOpen(!searchOpen); } }
            >{searchOpen? <CloseOutlinedIcon/>:<SearchTwoToneIcon />}
            </IconButton>
            <IconButton
              variant="outlined"
              onClick={(e) => {
                setOpenDialog(!openDialog);
                setIsForm(true);
                handleClearForm();
              }} 
              fontSize={"large"}
              style={{ color: "#1a588c" }}
            >
             {openDialog ? (
                <CloseOutlinedIcon
                />
              ) : (
                <AddBoxRoundedIcon
                />
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ borderTop: "1px solid #000" }}>
          <Grid
            item
            sm={openDialog ? 9 : 12}
            style={{ paddingTop: "0px", paddingLeft: "0px" }}
            className={"leftPanel-td"}
          >
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }} className={"loadTool"}>
              <TableContainer sx={{ maxHeight: "80vh" }} >
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                  stickyHeader 
                >
                  <TableHead style={{ background: "#ffffff" }}>
                    <TableRow>
                      {columns.map((item) => {
                          return (
                            <TableCell
                              key={item.key}
                              onClick={() => {
                                setSortFields(item.key);
                              }}
                              className={
                                (orderBy == item.key
                                  ? "active-th sort-" + order
                                  : " ") + " textLeft"
                              }
                            >
                              <span className="sortItem-arr">
                                <ArrowDropDownIcon fontSize="12" />
                              </span>{" "}
                              {item.label}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getTableRows().map((row) => (
                      <TableRow
                        key={row.id}
                        onClick={(e) => {
                          handleEdit(row.id);
                          setIsForm(false);
                        }}
                      >
                        <TableCell className="padd2">{row.client}</TableCell>
                        <TableCell className="padd2">{row.address}</TableCell>
                        <TableCell className="padd2"> {row.memCount}</TableCell>
                        <TableCell className="padd2"> {row.invoice}</TableCell>
                      </TableRow>
                    ))}
                  
                  </TableBody>
                </Table>
                </TableContainer>
              </Paper>
            </Box>            
          </Grid>
          {openDialog ? (
            <Grid item sm={3} className="v2-right-pane">
              <Grid container>
                <Grid item sm={8}>
                  <h4 className={"clienthead"}>
                    {clientId ? "Edit" : "Create"} Client
                  </h4>
                </Grid>

                <Grid item sm={4} style={{ textAlign:"right"}} >
                  {clientId ? (
                    <>
                      <IconButton
                        onClick={(e) => {
                          handleEdit(clientId);
                          setIsForm(true);
                        }}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        onClick={(e) => handleDelete(clientId)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              {isForm ? (
                <Grid item sm={12}>
                  <p>
                    <TextField
                      id="outlined-basic"
                      fullWidth={true}
                      label="Client Name"
                      variant="outlined"
                      value={clientField}
                      onChange={handleTypeInput}
                      type="text"
                   
                    />
                  </p>
                  <p>
                    <TextField
                      id="outlined-basic"
                      fullWidth={true}
                      label="address"
                      variant="outlined"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                    />
                  </p>
                  <p>
                    <TextField
                      id="outlined-basic"
                      fullWidth={true}
                      label="Invoice at latest "
                      variant="outlined"
                      value={invoice}
                      onChange={(e) => setInvoice(e.target.value)}
                      type="text"
                     
                    />
                  </p>
                  <p>
                    <TextField
                      id="outlined-basic"
                      fullWidth={true}
                      label="Comments"
                      variant="outlined"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      type="text"
                      multiline
                      row={2}
                    />
                  </p>
                  <Button
                    disabled={address.length == 0||clientField.length == 0}
                    onClick={handleSubmitForm}
                    variant="contained"
                  >
                    {clientId ? "Update" : "Create"}
                  </Button>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() =>{
                      if(clientId){setOpenDialog(false)}else{
                        setIsForm(false)
                      }
                    } }
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
              ) : (
                <Grid item sm={12} className="inf-table">

                  { (typeof singleClient.client !="undefined" &&typeof singleClient.client.id !="undefined")&&
              <Grid item sm={12}>
 
              <p className="pheadt">Client Name: <span className="pheadtt"> {singleClient.client.client}</span></p>
              <p className="pheadt">Address: <span className="pheadtt"> {singleClient.client.address}</span></p>
              <p className="pheadt">Invoice at Latest: <span className="pheadtt"> {singleClient.client.invoice}</span></p>
              <p className="pheadt">Comments : <span className="pheadtt"> {singleClient.client.comments}</span></p>
              </Grid>

}
                </Grid>
              )}
              <Grid item sm={12}>
                {clientId ? (
                  <ClientMember
                    clientMembers={singleClient.clientMembers}
                    handleEdit={handleEdit}
                    clientId={clientId}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Client;
