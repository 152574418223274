import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./login.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { _LOGIN_URL, _FORGOT_PASS_URL, _OTP_URL } from "../../Config/Constants";
import CmpnyLogoImg from "../../imgs/company-logo.png";
export class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      hasError: false,
      openFrogotPassword: false,
      frEmail: "",
      successMsg: "",
      isOtpScreen:false,
      token:"",
      frOTP:""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleForgotPassOpen = this.handleForgotPassOpen.bind(this);
    this.handleFrEmail = this.handleFrEmail.bind(this);
    this.handleFrOTP = this.handleFrOTP.bind(this);
    this.handleOTPSubmit=this.handleOTPSubmit.bind(this);
  }
  componentDidMount() {
    console.log("props", this.props);
  }
  handleForgotPassOpen() {
    this.setState({
      openFrogotPassword: !this.state.openFrogotPassword,
      username: "",
      password: "",
      hasError: "",
      frEmail: "",
      successMsg: "",
    });
  }
  handleFrEmail(e) {
    this.setState({ frEmail: e.target.value });
  }
  handleFrOTP(e){
    this.setState({ frOTP: e.target.value });
  }
  handleSubmit(event) {
    if (this.state.openFrogotPassword) {
      const params = { username: this.state.frEmail };
      axios
        .post(_FORGOT_PASS_URL, params)
        .then((response) => {
          if (response.data) {
            this.setState({
              successMsg: "Password reset link has been sent to your email",
              hasError: false,
            });
          }
        })
        .catch((error) => {
          console.log("ERR", error);
          this.setState({ hasError: true });
        });
    } else {
      const params = {
        username: this.state.username,
        password: this.state.password,
      };
      axios
        .post(_LOGIN_URL, params)
        .then((response) => {
          if (response.data && response.data.status==='success') {
            
            const token = response.data.token;
            this.setState({isOtpScreen:true,'token':token});

          } else {
            console.log("response.data",response.data)
            this.setState({ hasError: true });
          }
        })
        .catch((error) => {
          console.log("ERR", error);
          this.setState({ hasError: true });
        });
    }
  }
  handleOTPSubmit(){
    const params = {
      token: this.state.token,
      otp: this.state.frOTP,
    };
    axios
      .post(_OTP_URL, params)
      .then((response) => {
        if (response.data && response.data.status==='success') {
          const token=response.data.token;
          let _tokenStr = window.atob(token);
          console.log("_tokenStr",_tokenStr)
          let _tokenArr = JSON.parse(_tokenStr);
          _tokenArr['token'] = token;
          this.props.setToken(_tokenArr);
          window.location.reload();

        } else {
          this.setState({ hasError: true });
        }
      })
      .catch((error) => {
        console.log("ERR", error);
        this.setState({ hasError: true });
      });
  }
  handleUsername(e) {
    this.setState({ username: e.target.value, hasError: false });
  }
  handlePassword(e) {
    this.setState({ password: e.target.value, hasError: false });
  }
  render() {
    return (
      <section className={"gr-bg"} style={{ paddingTop: "0%" }}>
        <Container className="conainerList" style={{ paddingTop: "8%" }}>
          <Grid container>
            <Grid item sm={7}>
              <img
                src={CmpnyLogoImg}
                style={{ paddingLeft: "15%", paddingTop: "26%" }}
                width="55%"
              />
            </Grid>
            <Grid item sm={5}>
              <div className="login-container">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                >
                  {!this.state.openFrogotPassword &&!this.state.isOtpScreen  ? (
                    <Box
                      component="form"
                      style={{ textAlign: "center" }}
                      onSubmit={this.handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <div>
                        <br />
                        <b>Member's Login</b>
                        <input
                          type="text"
                          placeholder="Username/Email id"
                          value={this.state.username}
                          onChange={this.handleUsername}
                          className="logIp"
                        />
                      </div>
                      <div>
                        <br />

                        <input
                          type="password"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handlePassword}
                          className="logIp paassIp"
                        />
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="remember"
                            color="primary"
                            style={{ color: "white" }}
                          />
                        }
                        label="Remember me"
                      />
                      <div style={{ textAlign: "center" }}>
                        {this.state.hasError ? (
                          <p style={{ color: "red" }}>Invalid login details</p>
                        ) : null}
                        <button
                          className="login-btn"
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </button>
                      </div>
                      <Grid container>
                        <Grid item xs>
                          <p
                            onClick={this.handleForgotPassOpen}
                            className="login-fogrt"
                          >
                            Forgot password?
                          </p>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Box>
                  ) : (!this.state.openFrogotPassword&&this.state.isOtpScreen?<>
                  <Box
                      component="form"
                      onSubmit={this.handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <div>
                        <br />
                        <label>One Time Password</label>
                        <input
                          type="text"
                          value={this.state.frOTP}
                          onChange={this.handleFrOTP}
                          className="logIp"
                        />
                      </div>
                      <div>
<p>Check E-mail for One Time Password</p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {this.state.hasError ? (
                          <p style={{ color: "red" }}>Invalid OTP</p>

                        ) : null}

                        {this.state.successMsg ? (
                          <p style={{ color: "#fffff" }}>
                            {this.state.successMsg}
                          </p>
                        ) : null}
                        <button
                          className="login-btn"
                          type="button"
                          onClick={this.handleOTPSubmit}
                        >
                          SUBMIT
                        </button>
                      </div>




                      <Grid container>
                        <Grid item xs>
                         
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Box>
                  </>:
                    <Box
                      component="form"
                      onSubmit={this.handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <div>
                        <br />
                        <label>Email Address*</label>
                        <input
                          type="text"
                          value={this.state.frEmail}
                          onChange={this.handleFrEmail}
                          className="logIp"
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {this.state.hasError ? (
                          <p style={{ color: "red" }}>Invalid email address</p>
                        ) : null}

                        {this.state.successMsg ? (
                          <p style={{ color: "#fffff" }}>
                            {this.state.successMsg}
                          </p>
                        ) : null}
                        <button
                          className="login-btn"
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </button>
                      </div>
                      <Grid container>
                        <Grid item xs>
                          <p
                            onClick={this.handleForgotPassOpen}
                            className="login-fogrt"
                          >
                            Back to login
                          </p>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>{" "}
      </section>
    );
  }
}
