import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios'; // to perform API calls post and get 
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Header from '../Includes/Header';
import {_SETTINGS_PATH} from "../../Config/Constants";
import CloseIcon from '@mui/icons-material/Close';
const Education = (props) => { // Fat Arrow functions

  const [eduField, setEduField] = React.useState("");//usestate hook is used to handle state in functional components
  const [eduId, setEduId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [eduList, setEduList] = React.useState([]);
  // const [userForm,setFormData]=React.useState({name:"",email:""});

  React.useEffect(() => { // this hook help us to perform lifecycle methods in react
    getEducationList();
  }, []);
  const handleEduInput = (e) => {
    setEduField(e.target.value);
  }

  const handleSubmitForm = () => {
    const param = { education: eduField, id: eduId };
    axios.post(_SETTINGS_PATH+"/addEductaion", param)
      .then((response) => {
        getEducationList();
        handleClearForm();
      }).catch(err => {
        console.log(err);
      });
  }
  const getEducationList = () => {
    axios.get(_SETTINGS_PATH+"/getEducations")
      .then((response) => {
        console.log("responseresponse", response)
        setEduList(response.data.educations);
      }).catch(err => {
        console.log(err);
      });
  }

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id };
      axios.post(_SETTINGS_PATH+"/deleteEducation", param)
        .then((response) => {
          getEducationList();
        }).catch(err => {
          console.log(err);
        });
    }
  }
  const handleEdit = (id) => {
    axios.get(_SETTINGS_PATH+"/getEducations/" + id)
      .then((response) => {
        console.log("responseresponse", response)
        // setEduList(response.data.educations);
        if (response.data.education) {
          setEduField(response.data.education.education);
          setEduId(response.data.education.id);
          setOpenDialog(true);
        }
      }).catch(err => {
        console.log(err);
      });
  }
  const handleClearForm = () => {
    setEduField("");
    setEduId("");
    setOpenDialog(false);
  }

  return (
    <React.Fragment>
      <Header />
      {openDialog ?
        <Dialog open={openDialog} onClose={handleClearForm} >
             <DialogActions disableSpacing={true}>
            <IconButton className="close-pos close-span" onClick={e => { handleClearForm(false) }}><CloseIcon /></IconButton>
          </DialogActions>
        
          <DialogContent>
         
              
              <h4 className={"sethead"} >{eduId ? "Update" : "Create"} Education</h4>
            <div style={{ minWidth: 275 }}>

                <p><TextField id="outlined-basic" label="Education Name" variant="outlined"   value={eduField} onChange={handleEduInput} type="text" /></p>
                <Button  disabled={eduField.length == 0} onClick={handleSubmitForm} variant="contained">{eduId ? "Update" : "Create"}</Button>


                </div>

            
          </DialogContent>
        
        </Dialog>
        : null}
      <Container maxWidth="md" className="settings-container">
        
        <Grid container>
          <Grid item sm={8}>
          <h2 className={"filterhead"} >Education Qualification List</h2>
          </Grid>
          <Grid item  sm={4}>
          <Button variant='outlined' className="settingsnbtn" onClick={e => setOpenDialog(true)}>Add Education</Button>
          </Grid>
        </Grid> 
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="simple table" className={"sett-table"}>
            <TableHead>
              <TableRow>
                <TableCell  padding={'none'}>Education</TableCell>
                <TableCell align="right"  padding={'none'}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eduList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell padding={'none'} >
                    {row.education}
                  </TableCell>
                  <TableCell align="right"  padding={'none'}><IconButton onClick={e => handleEdit(row.id)} aria-label="delete">
                    <EditIcon />
                  </IconButton>
                    <IconButton onClick={e => handleDelete(row.id)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>

  );
}

export default Education;