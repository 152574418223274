import React from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Header from "../Includes/Header";
import { _TRIP_PATH } from "../../Config/Constants";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import "./project.css";
import AddProject from "./addProject";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
const columns = [
  {
    label: "Client Name",
    key: "clientName",
  },
  {
    label: "Project Name",
    key: "projectName",
  },
  {
    label: "Country",
    key: "countryName",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Start Date",
    key: "startDate",
  },
  {
    label: "Project Manager",
    key: "pmName",
  },
  {
    label: "Project Coordinator",
    key: "pmaName",
  },
];
const Project = (props) => {
  const [singleProject, setSingleProject] = React.useState({});
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectList, setProjectList] = React.useState([]);
  const [searchtext, setSearchtext] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("clientName");
  React.useEffect(() => {
    getProjectList();
  }, []); //[] => it will run only once at mounting
  // const handleTypeInput = (e) => {
  //   setProjectField(e.target.value);
  // };

  const getProjectList = () => {
    axios
      .get(_TRIP_PATH + "/getProject")
      .then((response) => {
        console.log("responseresponse", response);
        setProjectList(response.data.projectList);
        setOpenDialog(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id };
      axios
        .post(_TRIP_PATH + "/deleteProject", param)
        .then((response) => { if(response.data.status=='error'){
          alert(response.data.msg)
        }
        else
          getProjectList();
          setOpenDialog(false);
          alert("Project deleted successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleEdit = (id) => {
    axios
      .get(_TRIP_PATH + "/getProject/" + id)
      .then((response) => {
        // console.log("responseresponse", response);
        if (response.data.project) {
          setOpenDialog(true);
          console.log("responseresponse", response);
          setSingleProject(response.data.project);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const setSortFields = (data) => {
    //setOrderBy(order)
    let tempOrder = order;
    if (orderBy == data) tempOrder = order == "asc" ? "desc" : "asc";
    else tempOrder = "asc";
    setOrderBy(data);
    setOrder(tempOrder);
  };
  const getTableRows = () => {
    return projectList.filter((item) => {
      return (
        !searchOpen ||
        (searchOpen &&
          (item.projectName.toLowerCase().includes(searchtext.toLowerCase()) ||
            item.clientName.toLowerCase().includes(searchtext.toLowerCase())))
      );
    }).sort((a,b)=>{
      if(order=='asc')
           return a[orderBy].localeCompare(b[orderBy])
      return b[orderBy].localeCompare(a[orderBy])
    });
  };
  return (
    <React.Fragment>
      <Header />
      <Container
        maxWidth="100%"
        className="settings-container"
        style={{ marginTop: "70px" }}
      >
        <Grid container className={"page-head-bar"}>
          <Grid item sm={6}>
            <h3 className={"pagehead"}>Project Management</h3>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "right" }}>
            {searchOpen && (
              <input
                type="text"
                onChange={(e) => setSearchtext(e.target.value)}
                placeholder="Search"
                className="searchCSBox"
                style={{}}
              />
            )}
            <IconButton
              variant="outlined"
              onClick={(e) => {
                setSearchtext("");
                setSearchOpen(!searchOpen);
              }}
            >
              {searchOpen ? <CloseOutlinedIcon /> : <SearchTwoToneIcon />}
            </IconButton>
            <IconButton
              variant="outlined"
              onClick={(e) => {
                setOpenDialog(!openDialog);
                setSingleProject({});
              }} 
              fontSize={"large"}
              style={{ color: "#1a588c" }}
            >
              {openDialog ? (
                <CloseOutlinedIcon />
              ) : (
                <AddBoxRoundedIcon/>
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ borderTop: "1px solid #000" }}>
          <Grid
            item
            sm={openDialog ? 9 : 12}
            style={{ paddingTop: "0px", paddingLeft: "0px" }}
            className={"leftPanel-td"}
          >
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }} className={"loadTool"}>
                <TableContainer sx={{ maxHeight: "80vh" }}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"small"}
                    stickyHeader
                  >
                    <TableHead style={{ background: "#ffffff" }}>
                      <TableRow>
                        {columns.map((item) => {
                          return (
                            <TableCell
                              key={item.key}
                              onClick={() => {
                                setSortFields(item.key);
                              }}
                              className={
                                (orderBy == item.key
                                  ? "active-th sort-" + order
                                  : " ") + " textLeft"
                              }
                            >
                              <span className="sortItem-arr">
                                <ArrowDropDownIcon fontSize="12" />
                              </span>{" "}
                              {item.label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getTableRows().map((row) => (
                        <TableRow
                          key={row.id}
                          onClick={(e) => handleEdit(row.id)}
                        >
                          <TableCell className="padd2"> {row.clientName}</TableCell>
                          <TableCell className="padd2"> {row.projectName}</TableCell>
                          <TableCell className="padd2"> {row.countryName}</TableCell>
                          <TableCell className="padd2"> {row.status}</TableCell>
                          <TableCell className="padd2"> {dayjs(row.startDate).format('DD-MM-YYYY')}</TableCell>
                          <TableCell className="padd2"> {row.pmName}</TableCell>
                          <TableCell className="padd2"> {row.pmaName}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Grid>
          {openDialog ? (
            <Grid item sm={3} className="v2-right-pane padd3">
              <AddProject
                singleProject={singleProject}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                getProjectList={getProjectList}
                isView={false}
              />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Project;
